// src/services/createCompaniesService.js
export const createCompany = async (idToken, companyDetails) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/company`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    mode: "cors",
    body: JSON.stringify({
      body: companyDetails,
    }),
  });

  if (!response.ok) {
    console.log("Error");
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};
