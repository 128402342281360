import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import "./App.css";
import "./StaticStyles.css";
import "bootstrap/dist/css/bootstrap.min.css";

// Components and screens
import LandingScreen from "./screens/LandingScreen";
import SettingScreen from "./screens/SettingScreen";
import CompanyScreen from "./screens/CompanyScreen";
import EmployeeScreen from "./screens/EmployeeScreen";
import LoginScreen from "./screens/LoginScreen";
import StaticLandingPage from "./screens/StaticLandingPage";
import Protected from "./navigation/Protected";
import LoadingScreen from "./components/LoadingScreen";
import PayrollScreen from "./screens/PayrollScreen";
import PayrollReport from "./screens/PayrollReportScreen";
import AccessDeniedScreen from "./screens/AccessDeniedScreen";
import DocumentsScreen from "./screens/DocumentsScreen";
import AdminDocsScreen from "./screens/AdminDocsScreen";

import ViewDocs from "./screens/ViewDocs";

// Redux and react-query imports
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { store, persistor } from "./store";

// Mock selectors (Replace with your actual selectors)
const selectIsLoggedIn = (state) => state.user.isLoggedIn;
const selectUserDetails = (state) => ({
  isOwner: state.user.isOwner,
  accountType: state.user.accountType,
});

function AdminRoute({ children }) {
  const isAdmin = true;

  // Allow access if accountType is personal, or if accountType is business and isOwner is true
  return isAdmin ? children : <Navigate to="/portal/accessDenied" replace />;
}

function OwnerRoute({ children }) {
  const { isOwner, accountType } = useSelector(selectUserDetails);
  // Allow access if accountType is personal, or if accountType is business and isOwner is true
  return accountType === "personal" ||
    (accountType === "business" && isOwner) ? (
    children
  ) : (
    <Navigate to="/portal/accessDenied" replace />
  );
}

function RoutesComponent() {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<StaticLandingPage />} />
        <Route
          path="portal"
          element={
            isLoggedIn ? (
              <Protected isLoggedIn={isLoggedIn}>
                <LandingScreen />
              </Protected>
            ) : (
              <LoginScreen />
            )
          }
        />
        <Route
          path="/portal/accessDenied"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <AccessDeniedScreen />
            </Protected>
          }
        />
        {/* Other routes wrapped in Protected component */}
        <Route
          path="/portal/setting"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <SettingScreen />
            </Protected>
          }
        />
        {/* Routes that require owner access */}
        <Route
          path="/portal/company"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <OwnerRoute>
                <CompanyScreen />
              </OwnerRoute>
            </Protected>
          }
        />
        {/* ... additional routes ... */}
        <Route
          path="/portal/payroll"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <OwnerRoute>
                <PayrollScreen />
              </OwnerRoute>
            </Protected>
          }
        />

        <Route
          path="/portal/admin"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <AdminRoute>
                <AdminDocsScreen />
              </AdminRoute>
            </Protected>
          }
        />

        <Route
          path="/portal/documents"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <OwnerRoute>
                <DocumentsScreen />
              </OwnerRoute>
            </Protected>
          }
        />
        <Route
          path="/portal/documents/viewDocs"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <OwnerRoute>
                <ViewDocs />
              </OwnerRoute>
            </Protected>
          }
        />
        <Route
          path="/portal/payrollReport"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <OwnerRoute>
                <PayrollReport />
              </OwnerRoute>
            </Protected>
          }
        />
        {/* Employee route that may or may not require owner access */}
        <Route
          path="/portal/employee/:employeeId"
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <OwnerRoute>
                <EmployeeScreen />
              </OwnerRoute>
            </Protected>
          }
        />
      </Routes>
    </Router>
  );
}

function App() {
  const queryClient = new QueryClient();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Mock delay for demonstration; replace with actual logic as needed.
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={<LoadingScreen />} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          {loading ? <LoadingScreen /> : <RoutesComponent />}
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
