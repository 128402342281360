// src/store/userSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isLoggedIn: false,
    accessToken: null,
    idToken: null,
    refreshToken: null,
    name: "",
    email: "",
    isOwner: null,
    userId: null,
    accountType: null, // Initialize accountType
  },
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.accessToken = action.payload.accessToken;
      state.idToken = action.payload.idToken;
      state.refreshToken = action.payload.refreshToken;
      state.name = action.payload.name || "";
      state.email = action.payload.email || "";
      state.isOwner = action.payload.isOwner;
      state.userId = action.payload.userId;
      state.accountType = action.payload.accountType; // Update accountType on login
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.accessToken = null;
      state.idToken = null;
      state.refreshToken = null;
      state.name = "";
      state.email = "";
      state.isOwner = null;
      state.userId = null;
      state.accountType = null; // Reset accountType on logout
    },
  },
});

export const { login, logout } = userSlice.actions;

export const selectIsLoggedIn = (state) => state.user.isLoggedIn;
export const selectTokens = (state) => ({
  accessToken: state.user.accessToken,
  idToken: state.user.idToken,
  refreshToken: state.user.refreshToken,
});
export const selectUserDetails = (state) => ({
  name: state.user.name,
  email: state.user.email,
  isOwner: state.user.isOwner,
  userId: state.user.userId,
  accountType: state.user.accountType, // Added new attribute
});

export default userSlice.reducer;
