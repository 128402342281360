import Screen from "../components/Screen";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
export default function ErrorModal({ message, show, continueButton }) {
  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title> {message.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message.body}</Modal.Body>
      <Modal.Footer>
        <Button
          style={{ backgroundColor: "#213555" }}
          onClick={() => continueButton()}
        >
          {message.buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
