import React from "react";
import { Link } from "react-router-dom";
import { ListGroup, Spinner } from "react-bootstrap";
import { FaBuilding } from "react-icons/fa"; // Change the icon to something more appropriate for companies

export default function CompaniesList({ companies, handleClick, screen }) {
  if (companies.length === 0) {
    return (
      <div style={{ marginTop: "20px" }}>
        There are no companies added. Please add companies.
      </div>
    );
  }

  return (
    <div>
      <ListGroup>
        {companies.map((company) => (
          <Link
            to={
              screen === "payroll"
                ? "/portal/company"
                : "/portal/documents/viewDocs"
            }
            state={{ companyData: company }}
            style={{ textDecoration: "none" }}
            onClick={() => handleClick(company.company_id)}
          >
            <ListGroup.Item
              key={company.company_id}
              action
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <FaBuilding style={{ marginRight: "10px", color: "#023e77" }} />
                <div
                  style={{
                    textDecoration: "none",
                    color: "#213555",
                    fontWeight: 500,
                    marginLeft: 20,
                  }}
                >
                  {company.name}
                </div>
              </div>
              {screen === "payroll" && (
                <div
                  to={`/portal/company/${company.company_id}`}
                  state={{ companyData: company }}
                  style={{ color: "#213555" }}
                >
                  Edit
                </div>
              )}
            </ListGroup.Item>
          </Link>
        ))}
      </ListGroup>
    </div>
  );
}
