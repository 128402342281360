import React, { useState, useEffect } from "react";
import Screen from "../components/Screen";
import {
  Card,
  Container,
  Row,
  Col,
  Spinner,
  Button,
  Form,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { FaFileInvoiceDollar, FaAngleDown } from "react-icons/fa";
import DetailsCard from "../components/DetailsCard";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getEmployeesByCompany } from "../services/getEmployeesService";
import { getPayrollReport } from "../services/getPayrollReport";
import { useSelector, useDispatch } from "react-redux";
import { selectTokens, logout } from "../store/userSlice";
import ErrorModal from "../components/ErrorModal";
import EmployeeList from "../components/EmployeeList";
import EmployeeForm from "../components/EmployeeForm";
import { createEmployee } from "../services/employeeServices";
import PayrollReportTable from "../components/PayrollReportTable";
import LoadingScreen from "../components/LoadingScreen";

export default function PayrollReportScreen() {
  const location = useLocation();
  const navigate = useNavigate(); // <-- useNavigate hook

  const companyData = location.state?.companyData;
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showEmployeeModal, setShowEmployeeModal] = useState(false);
  const [validated, setValidated] = useState(false); // Added state for form validation
  const { idToken } = useSelector(selectTokens);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [report, setReport] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Add this state for managing loading spinner

  useEffect(() => {
    if (companyData === undefined) {
      navigate("/portal");
    }
  }, [companyData, navigate]);
  const handleLogout = () => {
    dispatch(logout());
  };
  const generatePayrollReport = async () => {
    if (!startDate || !endDate) {
      setValidated(true); // Trigger form validation
      return; // Don't proceed if dates are not selected
    }

    setIsLoading(true); // Activate spinner

    try {
      const payrollReport = await getPayrollReport(
        idToken,
        companyData?.company_id,
        startDate,
        endDate
      );
      setReport(payrollReport);
    } catch (error) {
      setError(error);
      setShow(true);
    } finally {
      setIsLoading(false); // Deactivate spinner after API call is finished
    }
  };
  const handleDateSelection = (value) => {
    const today = new Date();
    let startDate;
    let endDate = today;

    switch (value) {
      case "thisWeek":
        let day = today.getDay(); // 0 = Sunday, 1 = Monday, etc.
        let diffToMonday = today.getDate() - day + (day === 0 ? -6 : 1); // if it's Sunday, go back 6 days to get Monday
        startDate = new Date(today.setDate(diffToMonday));

        let diffToSunday = diffToMonday + 6; // 6 days after Monday
        endDate = new Date(today.setDate(diffToSunday));
        break;

      case "thisMonth":
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Day 0 of the next month is the last day of the current month
        break;

      case "thisYear":
        startDate = new Date(today.getFullYear(), 0, 1); // January 1st
        endDate = new Date(today.getFullYear(), 11, 31); // December 31st
        break;

      default:
        startDate = "";
        endDate = "";
    }

    setStartDate(startDate.toISOString().split("T")[0]);
    setEndDate(endDate.toISOString().split("T")[0]);
  };

  return companyData ? (
    <Screen backButton title={`Company: ${companyData?.name}`}>
      <ErrorModal
        continueButton={() => {
          handleLogout();
          setError(false);
          setShow(false);
        }}
        show={show}
        message={{
          title: `${
            error?.message === "HTTP error! status: 401"
              ? "Session expired!"
              : error
          }`,
          body: `Please try logging in again. ${
            error?.message === "HTTP error! status: 401"
              ? ""
              : `Error message: ${error}`
          }`,
          buttonText: "Continue",
        }}
      />
      <Container fluid>
        {isLoading ? (
          <LoadingScreen loadingText="Generating Report..." />
        ) : (
          <>
            <Row>
              <Col md={10}>
                <DetailsCard
                  hide={true}
                  data={companyData}
                  dataType="company"
                />
                <EmployeeForm
                  company_id={companyData.company_id}
                  showModal={showEmployeeModal}
                  setShowModal={setShowEmployeeModal}
                />
              </Col>
            </Row>
            <div className="secondaryHeader">Generate Payroll Report:</div>

            <Form noValidate validated={validated}>
              <Row className="align-items-center">
                {" "}
                {/* Aligned items vertically */}
                <Col md={4}>
                  <Form.Group controlId="timePeriod">
                    <Form.Label>Choose Time Period</Form.Label>
                    <div style={{ marginTop: "10px" }}>
                      <Button
                        // variant="outline-primary"
                        className="button-outline time-period-button"
                        onClick={() => handleDateSelection("thisWeek")}
                      >
                        This Week
                      </Button>
                      <Button
                        // variant="outline-primary"
                        className="button-outline time-period-button"
                        onClick={() => handleDateSelection("thisMonth")}
                      >
                        This Month
                      </Button>
                      <Button
                        className="button-outline time-period-button"
                        // variant="outline-primary"
                        onClick={() => handleDateSelection("thisYear")}
                      >
                        This Year
                      </Button>
                    </div>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="startDate">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      required
                      isInvalid={!startDate && validated}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please select a start date.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="endDate">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      required
                      isInvalid={!endDate && validated}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please select an end date.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Button
                className="button-secondary payroll-report-button"
                onClick={generatePayrollReport}
              >
                <FaFileInvoiceDollar />
                Generate Payroll
              </Button>
            </Form>
            <PayrollReportTable
              report={report}
              startDate={startDate}
              endDate={endDate}
              companyName={companyData?.name}
            />
          </>
        )}
      </Container>
    </Screen>
  ) : (
    <ErrorModal
      continueButton={() => {
        handleLogout();
        setError(false);
        setShow(false);
      }}
      show={show}
      message={{
        title: `${
          error?.message === "HTTP error! status: 401"
            ? "Session expired!"
            : "error"
        }`,
        body: `Please try logging in again. ${
          error?.message === "HTTP error! status: 401"
            ? ""
            : `Error message: ${error}`
        }`,
        buttonText: "Continue",
      }}
    />
  );
}
