import React, { useState, useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";

function formatElapsedTime(elapsedTime) {
  const seconds = Math.floor((elapsedTime / 1000) % 60);
  const minutes = Math.floor((elapsedTime / (1000 * 60)) % 60);
  const hours = Math.floor((elapsedTime / (1000 * 60 * 60)) % 24);

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
}

export default function TimeStamp({
  isLoading,
  isStartTime,
  handleCreateOrUpdateTimeCard,
  timeStamp,
}) {
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    // Calculate elapsed time when the component loads
    if (!isStartTime && timeStamp) {
      const startTime = new Date(timeStamp).getTime();
      const currentTime = new Date().getTime();
      const initialElapsed = currentTime - startTime;
      setElapsedTime(initialElapsed);

      // Update elapsed time every second
      const intervalId = setInterval(() => {
        setElapsedTime((prevElapsedTime) => prevElapsedTime + 1000);
      }, 1000);

      return () => {
        // Clear the interval when the component unmounts
        clearInterval(intervalId);
      };
    }
  }, [isStartTime, timeStamp]);

  const handleEndTimestamp = async () => {
    try {
      await handleCreateOrUpdateTimeCard();

      setElapsedTime(0);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="timer">
        <div className="timer-header">Hours Worked:</div>
        <div className="timer-display">{formatElapsedTime(elapsedTime)}</div>
        <Button
          size="lg"
          variant="primary"
          className="time-stamp-button"
          onClick={
            isStartTime ? handleCreateOrUpdateTimeCard : handleEndTimestamp
          }
          disabled={isLoading}
        >
          <span style={{ fontWeight: "bold" }}>
            {isLoading ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : isStartTime ? (
              "Clock In"
            ) : (
              "Clock Out"
            )}
          </span>
        </Button>
      </div>
    </div>
  );
}
