import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { selectTokens, selectUserDetails, logout } from "../store/userSlice";
import { getAllDocuments } from "../services/getAllDocumentsService";
import { useLocation } from "react-router-dom";
import { generatePresignedUrlAndUpload } from "../services/uploadDocumentService";
import Screen from "../components/Screen";
import ErrorModal from "../components/ErrorModal";
import { Spinner } from "react-bootstrap";
import { FaFileAlt, FaDownload } from "react-icons/fa";

export default function AdminDocsScreen() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false); // State to track uploading status
  const [show, setShow] = useState(false);
  const [appError, setError] = useState(null);
  const userDetails = useSelector(selectUserDetails);
  const [showUploadControls, setShowUploadControls] = useState(false);

  const { idToken } = useSelector(selectTokens);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const location = useLocation();
  //   const companyData = location.state?.companyData;
  const companyData = { name: "TestName" };

  const companyNameForURL = companyData.name.replace(/\s+/g, "-");
  const userName = userDetails.email.split("@")[0];
  const fileInputRef = React.createRef();

  const {
    data: documents,
    isLoading,
    error,
    refetch,
  } = useQuery(
    ["documents", userName, companyNameForURL],
    () =>
      getAllDocuments(
        idToken,
        "acctechllc/acctech-llc/ZelleQRCode-(1)-(2).pdf"
      ),
    {
      onError: (error) => {
        setError(error);
        setShow(true);
      },
      onSuccess: () => setIsUploading(false), // Turn off the spinner when documents are fetched successfully
    }
  );

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      return;
    }
    setIsUploading(true); // Indicate that file upload has started
    const fileName = selectedFile.name.replace(/\s+/g, "-");

    try {
      await generatePresignedUrlAndUpload(
        idToken,
        userName,
        companyNameForURL,
        fileName,
        selectedFile
      );
      refetch(); // Refresh the documents list

      // After successful upload, reset the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      setSelectedFile(null);
      setShowUploadControls(false);
    } catch (uploadError) {
      console.error(uploadError);
      setError(uploadError.message);
      setShow(true);
    } finally {
      setIsUploading(false); // Reset uploading status regardless of outcome
    }
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const getFileName = (path) => {
    return path.split("/").pop();
  };

  // Function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      // 'undefined' to use the browser's locale
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  console.log({ documents });

  return (
    <Screen backButton={true} title={`Company: ${companyData.name}`}>
      <ErrorModal
        continueButton={() => {
          handleLogout();
          setError(null);
          setShow(false);
        }}
        show={show}
        message={{
          title:
            appError === "HTTP error! status: 401"
              ? "Session expired!"
              : "Error",
          body: `Please try logging in again. ${
            appError === "HTTP error! status: 401"
              ? ""
              : `Error message: ${appError}`
          }`,
          buttonText: "Continue",
        }}
      />

      <div className="landing-text-container">
        {userDetails.accountType === "personal" ? (
          <>
            Showing <span style={{ color: "#326697" }}>personal</span>{" "}
            documents:
          </>
        ) : (
          <>
            Showing documents for{" "}
            <span style={{ color: "#326697" }}>{companyData.name}:</span>
          </>
        )}
      </div>

      <div className="upload-controls">
        {!showUploadControls ? (
          <button
            className="primary-button"
            onClick={() => setShowUploadControls(true)}
          >
            Upload Document
          </button>
        ) : (
          <>
            <input
              type="file"
              onChange={handleFileChange}
              accept="application/pdf"
              className="file-input"
              ref={fileInputRef}
            />
            <button
              className="upload-button"
              onClick={handleUpload}
              disabled={!selectedFile || isUploading}
            >
              {isUploading ? "Uploading..." : "Upload"}
            </button>
          </>
        )}
      </div>

      {isLoading ? (
        <div className="spinner-container">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : documents?.length > 0 ? (
        <div className="document-list-container">
          {documents.map((doc, index) => (
            <div key={index} className="document-item">
              <div className="document-start-container">
                <FaFileAlt className="file-icon" />
                <span className="file-name">{getFileName(doc.fileName)}</span>
              </div>
              <div className="document-actions-container">
                <span className="file-date">
                  {formatDate(doc.lastModified)}
                </span>
                <a
                  href={doc.downloadUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="file-download-link"
                >
                  <FaDownload /> Download
                </a>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="no-documents-message">No files uploaded yet.</div>
      )}
    </Screen>
  );
}
