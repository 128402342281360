import React from "react";

function Divider({ color }) {
  return (
    <div
      style={{ backgroundColor: color ? color : "rgba(255, 255, 255, 0.20)" }}
      className="custom-divider"
    ></div>
  );
}

export default Divider;
