import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import aboutGraphic from "../../images/pages/accounting.jpg";
import {
  FaHandshake,
  FaEnvelope,
  FaPencilRuler,
  FaAnchor,
  FaChessKnight,
} from "react-icons/fa";

// import { TiTools, TiStarburst, TiAnchorOutline } from "react-icons/ti";

function StaticAboutUsPage() {
  return (
    <>
      <section class="bg-one about static-section" id="about">
        <div class="container">
          <div class="row">
            {/* <!-- section title --> */}
            <div
              class="title text-center wow fadeIn"
              data-wow-duration="1500ms"
            >
              <h2>
                <span class="darkTitle"> About </span>
                <span class="lightTitle">Us</span>
              </h2>
              <div class="borderLight"></div>
            </div>
            {/* <!-- /section title --> */}

            {/* <!-- About item --> */}
            <div
              class="col-md-4 text-center wow fadeInUp"
              data-wow-duration="500ms"
            >
              <div class="block">
                <div class="icon-box">
                  <FaPencilRuler color="rgba(255, 255, 255, 0.678)" />
                </div>
                {/* <!-- Express About Yourself --> */}
                <div class="content text-center">
                  <h3 class="ddd">We're Creative</h3>
                  <p>
                    Here at AccTech, we take great pride in making each of our
                    projects unique and catchy to the public eye!
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- End About item --> */}

            {/* <!-- About item --> */}
            <div
              class="col-md-4 text-center wow fadeInUp"
              data-wow-duration="500ms"
              data-wow-delay="250ms"
            >
              <div class="block">
                <div class="icon-box">
                  <FaChessKnight color="rgba(255, 255, 255, 0.678)" />
                </div>
                {/* <!-- Express About Yourself --> */}
                <div class="content text-center">
                  <h3>We're Professional</h3>
                  <p>
                    Here at Acctech, we take careful steps to make sure your
                    experience with us as professional and smooth as possible!
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- End About item --> */}

            {/* <!-- About item --> */}
            <div
              class="col-md-4 text-center wow fadeInUp"
              data-wow-duration="500ms"
              data-wow-delay="500ms"
            >
              <div class="block kill-margin-bottom">
                <div class="icon-box">
                  <FaAnchor color="rgba(255, 255, 255, 0.678)" />
                </div>
                {/* <!-- Express About Yourself --> */}
                <div class="content text-center">
                  <h3>We're Experimental</h3>
                  <p>
                    Here at Acctech, we are trail blazers! We are always looking
                    for new ways to pave a path in our industry and explore
                    different ways to make our service as enticing as possible!
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- End About item --> */}
          </div>
          {/* <!-- End row --> */}
        </div>
        {/* <!-- End container --> */}
      </section>

      <section class="section about-2 padding-0 bg-dark">
        <div className="container-fluid">
          <div className="row no-gutters d-flex align-items-stretch">
            <div
              className="col-12 col-md-6"
              style={{
                backgroundImage: `url(${aboutGraphic})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                minHeight: "200px", // Set a minimum height for mobile
              }}
            >
              {/* No img tag needed, since the image is applied as a background */}
            </div>
            <div class="col-md-6">
              <div class="content-block">
                <h2>Who We Are</h2>
                <p>
                  Acctech LLC is a small public accounting firm motivated to
                  provide accounting, payroll and income tax service to the
                  small businesses in greater Minneapolis and St Paul area. The
                  owner of Acctech LLC has vast experience in accounting and
                  financial services.
                </p>
                <p>
                  Our company is based on the belief that our customers' needs
                  are of the utmost importance. Our entire team is committed to
                  meet those needs. As a result, a high percentage of our
                  business is from repeat customers and customers referrals. We
                  would welcome the opportunity to earn your trust and deliver
                  to you the best service in the industry.
                </p>
                <p>
                  {" "}
                  Our goal is to help our clients increase their profitability
                  and net financial worth by creating transparent financial
                  statements. Hence, they can understand their financial
                  position, utilize finite cash with accounted and calculated
                  cash management, and file their taxes timely and accurately.
                  Our entire team is committed to achieve those goals. As a
                  result, our business is growing from retained customers and
                  their referrals.
                </p>

                <div class="row">
                  <div class="col-md-6">
                    <div class="media row">
                      <div class="pull-left col-2">
                        <FaHandshake size={"2em"} color="#88b6ff" />
                      </div>
                      <div class="media-body col-10">
                        <h4 class="media-heading">Checkout Our Services</h4>
                        <p>
                          Checkout all the amazing services we offer.
                          <a href="#services"> View Services...</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="media row">
                      <div class="pull-left col-2">
                        <FaEnvelope size={"2em"} color="#88b6ff" />
                      </div>
                      <div class="media-body col-10">
                        <h4 class="media-heading">Need help?</h4>
                        <p>
                          Why don't you contact us with your questions.
                          <a href="#contact"> Contact Us...</a>
                        </p>
                        {/* <!-- <button class="newButton"    
                                >
                                    Contact Us
                                </button> --> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default StaticAboutUsPage;
