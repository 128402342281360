// LoginPrompt.js
import React from "react";

const LoginPrompt = ({ handleTabChange }) => {
  return (
    <div>
      <h1>New Here?</h1>
      <h2>Sign up and discover</h2>
      <button
        className="form-button-alt"
        onClick={() => handleTabChange("signup")}
      >
        Sign Up
      </button>
    </div>
  );
};

export default LoginPrompt;
