import React, { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Card, Container, Row, Col, Spinner, Button } from "react-bootstrap";
import { useQuery } from "react-query";
import Screen from "../components/Screen";
import DetailsCard from "../components/DetailsCard";
import { getTimeCardByEmployee } from "../services/getTimeCardService"; // Import your service
import { useSelector, useDispatch } from "react-redux";
import { selectTokens, logout } from "../store/userSlice";
import ErrorModal from "../components/ErrorModal";
import TimeCardList from "../components/TImeCardList";

export default function EmployeeScreen() {
  const { employeeId } = useParams();
  const location = useLocation();
  const { idToken } = useSelector(selectTokens);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);

  const employeeData = location.state?.employeeData;

  const handleLogout = () => {
    dispatch(logout());
  };

  const {
    data: timeCards,
    isLoading,
    isError,
  } = useQuery(
    ["timeCards", employeeId],
    () => getTimeCardByEmployee(idToken, employeeId), // Replace yourIdToken with the actual token
    {
      enabled: !!employeeId, // Only run the query if employeeId is available
      onError: (error) => {
        setError(error);
        setShow(true);
      },
    }
  );

  console.log(timeCards); // This will log the fetched time cards

  return (
    <Screen backButton title={`Employee: ${employeeData.firstName}`}>
      <ErrorModal
        continueButton={() => {
          handleLogout();
          setError(false);
          setShow(false);
        }}
        show={show}
        message={{
          title: "There was an error",
          body: `Please try logging in again. Error message: ${error}`,
          buttonText: "Continue",
        }}
      />
      <Container style={{ paddingBottom: 100 }}>
        <Row>
          <Col md={10}>
            <DetailsCard data={employeeData} dataType="employee" />
            {/* You can now render the timeCards data here */}

            <div className="secondaryHeader">Recent Time Entry:</div>

            {isLoading ? (
              <div className="text-center">
                <Spinner animation="border" role="status"></Spinner>
              </div>
            ) : (
              <TimeCardList timeCards={timeCards} />
            )}
          </Col>
        </Row>
      </Container>
    </Screen>
  );
}
