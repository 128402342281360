import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import { getTimeCardByEmployee } from "../services/getTimeCardService";
import { useSelector, useDispatch } from "react-redux";
import { selectTokens, selectUserDetails, logout } from "../store/userSlice";
import ErrorModal from "../components/ErrorModal";
import TimeCardList from "../components/TImeCardList";
import { createTimecard, updateTimecard } from "../services/timecardServices";
import { setTimeCardData } from "../store/timeCardSlice";
import TimeStamp from "../components/TimeStamp";

export default function WorkerLandingScreen() {
  const { idToken } = useSelector(selectTokens);
  const { userId } = useSelector(selectUserDetails);
  const { isActive, timeCardId, timeStamp } = useSelector(
    (state) => state.timeCardData
  );

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [isStartTime, setIsStartTime] = useState(!isActive);
  const [timestampId, setTimestampId] = useState(timeCardId);

  const {
    data: timeCards,
    isError,
    refetch,
    isSuccess,
  } = useQuery(
    ["timeCards", userId],
    () => getTimeCardByEmployee(idToken, userId),
    {
      enabled: !!userId,
      onError: (error) => {
        setError(error);
        setShow(true);
      },
    }
  );
  const mutation = useMutation((newTimecardDetails) =>
    createTimecard(idToken, newTimecardDetails)
  );

  useEffect(() => {
    if (isSuccess && timeCards?.length > 0) {
      const sortedTimeCards = [...timeCards].sort((a, b) => {
        return new Date(b.startTime) - new Date(a.startTime);
      });
      const latestTimeCard = sortedTimeCards[0];

      // console.log(latestTimeCard);

      // Check if the latest time card has an endDate
      const isCardActive = latestTimeCard?.endTime === "";

      // console.log(isCardActive);

      // console.log({ isStartTime });

      setIsStartTime(!isCardActive);
      setTimestampId(latestTimeCard.time_card_id);

      dispatch(
        setTimeCardData({
          isActive: isCardActive,
          timeCardId: latestTimeCard.time_card_id, // Adjust field names as necessary
          timeStamp: latestTimeCard.startTime,
        })
      );
    }
  }, [isSuccess, timeCards, dispatch]);

  useEffect(() => {
    const getUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setUserLocation({
              latitude: position.coords?.latitude,
              longitude: position.coords?.longitude,
            });
          },
          (error) => {
            console.error("Error getting user location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    getUserLocation();
  }, []);

  const handleCreateOrUpdateTimeCard = async () => {
    setIsLoading(true);

    if (!userLocation) {
      setIsLoading(false);
      setError("User location is not available.");
      setShow(true);
      return;
    }

    const currentDateTime = new Date().toISOString();
    let newTimecardDetails = {
      employeeId: userId,
      startTime: currentDateTime,
      locationStart: `${userLocation.latitude}, ${userLocation.longitude}`,
      locationEnd: "",
      endTime: "",
    };

    try {
      if (isStartTime) {
        const result = await mutation.mutateAsync(newTimecardDetails);
        setTimestampId(result.time_card_id);

        dispatch(
          setTimeCardData({
            isActive: true,
            timeCardId: result.time_card_id,
            timeStamp: currentDateTime,
          })
        );
        setIsStartTime(false);
      } else {
        await updateTimecard(idToken, timestampId, {
          endTime: currentDateTime,
          locationEnd: `${userLocation.latitude}, ${userLocation.longitude}`,
        });

        // Reset timeCardData to default values
        dispatch(
          setTimeCardData({
            isActive: false,
            timeCardId: null,
            timeStamp: null,
          })
        );

        setIsStartTime(true);
      }

      setIsLoading(false);
      refetch();
    } catch (error) {
      setIsLoading(false);
      setError(error);
      setShow(true);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <ErrorModal
        continueButton={() => {
          if (error !== "User location is not available.") {
            handleLogout();
          }
          setError(false);
          setShow(false);
        }}
        show={show}
        message={
          error === "User location is not available."
            ? {
                title: "Location not found",
                body: `Please try again. Error message: ${error}`,
                buttonText: "Continue",
              }
            : {
                title: `${
                  error?.message === "HTTP error! status: 401"
                    ? "Session expired!"
                    : error
                }`,
                body: `Please try logging in again. ${
                  error?.message === "HTTP error! status: 401"
                    ? ""
                    : `Error message: ${error}`
                }`,
                buttonText: "Continue",
              }
        }
      />

      {isError ? (
        <div>Loading time entries...</div>
      ) : timeCards ? (
        <Accordion style={{ marginBottom: 30 }} defaultActiveKey="1">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="accordianTitleContainer">
                Your recent time logs:
              </div>{" "}
            </Accordion.Header>
            <Accordion.Body>
              <TimeCardList timeCards={timeCards} />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ) : (
        <div>No time entries to show</div>
      )}

      <TimeStamp
        isLoading={isLoading}
        isStartTime={isStartTime}
        handleCreateOrUpdateTimeCard={handleCreateOrUpdateTimeCard}
        timeStamp={timeStamp}
      />
    </>
  );
}
