// SignupPrompt.js
import React from "react";

const SignupPrompt = ({ handleTabChange }) => {
  return (
    <div>
      <h1>Already an user?</h1>
      <h2>If you already have an account then just sign in</h2>
      <button
        className="form-button-alt"
        onClick={() => handleTabChange("login")}
      >
        Sign In
      </button>
    </div>
  );
};

export default SignupPrompt;
