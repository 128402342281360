import React, { useState } from "react";
import { Modal, Button, Form, Spinner, Alert } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import { createEmployee } from "../services/employeeServices";
import { useSelector } from "react-redux";
import { selectTokens } from "../store/userSlice";

export default function EmployeeForm({ showModal, setShowModal, company_id }) {
  const { idToken } = useSelector(selectTokens);
  const queryClient = useQueryClient();

  const initialState = {
    firstName: "",
    lastName: "",
    position: "",
    companyId: company_id,
  };

  const [formData, setFormData] = useState(initialState);

  const mutation = useMutation(
    (newEmployee) => createEmployee(idToken, newEmployee),
    {
      onSuccess: () => {
        handleCloseModal();
        queryClient.invalidateQueries("employees");
      },
    }
  );

  const handleSubmit = () => {
    mutation.mutate(formData);
  };

  const handleCloseModal = () => {
    setFormData({ ...initialState, companyId: company_id });
    setShowModal(false);
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header
        style={{
          backgroundImage: "linear-gradient(200deg,#2b6cd5, #023e77,#001930)",
          color: "#F5EFE7",
          position: "relative",
        }}
      >
        <Modal.Title>Create Employee</Modal.Title>
        <button
          className="custom-close-button"
          onClick={() => setShowModal(false)}
        >
          &times;
        </button>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#f0f0f0" }}>
        {mutation.isError ? (
          <Alert variant="danger">
            An error occurred: {mutation.error.message}
          </Alert>
        ) : null}

        <Form>
          <Form.Group>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              value={formData.firstName}
              onChange={(e) =>
                setFormData({ ...formData, firstName: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Label style={{ marginTop: 20 }}>Last Name</Form.Label>
            <Form.Control
              type="text"
              value={formData.lastName}
              onChange={(e) =>
                setFormData({ ...formData, lastName: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group style={{ marginTop: 20 }}>
            <Form.Label>Position</Form.Label>
            <Form.Control
              type="text"
              value={formData.position}
              onChange={(e) =>
                setFormData({ ...formData, position: e.target.value })
              }
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer
        style={{ backgroundColor: "#f0f0f0", flexDirection: "column" }}
      >
        <Button
          style={{
            backgroundColor: "#032545",
            width: "100%",
          }}
          variant="secondary"
          onClick={handleSubmit}
          disabled={mutation.isLoading}
        >
          {mutation.isLoading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Saving...
            </>
          ) : (
            "Create Employee"
          )}
        </Button>
        <Button
          variant="secondary"
          style={{ width: "100%", marginTop: "10px" }}
          onClick={handleCloseModal}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
