import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";

import { FaCheckCircle, FaTimesCircle, FaBriefcase } from "react-icons/fa";
// import { useNavigation } from "react-router-dom";

export default function ServicesPlanCard({ plan, setError, setShow }) {
  //   const navigate = useNavigation();

  return (
    <div className="service-plan-cards-card">
      <div className="service-plan-cards-card-header">{plan.title}</div>
      <div className="service-plan-cards-card-body">
        <ul>
          {plan.data.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        {plan.hasPlan ? (
          <>
            <Button
              href="/portal/payroll"
              //   onClick={() => navigate.push("/portal")}
              className="service-plan-cards-card-button"
            >
              Access Now
            </Button>
            <div className="service-plan-cards-card-access-info">
              <FaCheckCircle /> You have access to this plan
            </div>
          </>
        ) : (
          <>
            {/* <div className="service-plan-cards-card-price">{plan.price}</div> */}
            <Button
              href="/#contact"
              className="service-plan-cards-card-button service-plan-cards-card-button-price"
            >
              Request Access
            </Button>
            <div className="service-plan-cards-card-no-access-info">
              <FaTimesCircle /> You don't have access to this plan
            </div>
          </>
        )}
      </div>
    </div>
  );
}
