import React from "react";
import { useNavigate } from "react-router-dom";
import AccessDeniedImage from "../images/graphics/oops.png"; // Update the path according to where you saved your image
import bg from "../images/graphics/oopsBG2.png"; // Update the path according to where you saved your image

const AccessDeniedScreen = () => {
  const navigate = useNavigate();

  const handleBackToPortal = () => {
    navigate("/portal");
  };

  return (
    <div
      style={{
        background: `linear-gradient(rgba(0,30,64, .7), rgba(0,30,64, .7)), url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        position: "relative",
      }}
      className="access-denied-container"
    >
      <div className="access-denied-content">
        <img width={"100%"} src={AccessDeniedImage} alt="Access Denied" />{" "}
        {/* Image added here */}
        <h1>Access Denied</h1>
        <p>You do not have permission to view this page.</p>
        <button onClick={handleBackToPortal} className="btn-back-portal">
          Return to Portal
        </button>
      </div>
    </div>
  );
};

export default AccessDeniedScreen;
