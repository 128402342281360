import React, { useState } from "react";
import LoginForm from "../components/LoginForm";
import SignupForm from "../components/SignupForm";
import LoginPrompt from "../components/LoginPrompt";
import SignupPrompt from "../components/SignupPrompt";
import heroImage from "../images/slider/hero-area2.jpg";
import heroGraphics from "../images/graphics/login2.png";
import heroGraphics2 from "../images/graphics/login3.png";

import TopNavbar from "../navigation/TopNavbar";
import logo from "../images/logos/LogoDarkLongAlt.png";
import {
  Container,
  Navbar,
  Nav,
  Button,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import { Link as ScrollLink } from "react-scroll";

import "../App.css";

export default function LoginScreen({ setIsLoggedIn }) {
  const [activeTab, setActiveTab] = useState("login");
  const [successMessage, setSuccessMessage] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [signupData, setSignupData] = useState({
    name: "",
    email: "",
    password: "",
    userType: "user",
    accountType: "personal", // Initial value for accountType
  });

  const handleSignup = () => {};

  return (
    <>
      {" "}
      {successMessage && (
        <Alert
          variant="success"
          onClose={() => setSuccessMessage(null)}
          dismissible
          className="alert-style"
          style={{ position: "fixed" }}
        >
          {successMessage}
        </Alert>
      )}
      <TopNavbar expanded={expanded} setExpanded={setExpanded} logo={logo}>
        <Navbar.Collapse className="justify-content-end">
          <Button
            className="static-landing-page-portal-button"
            variant="outline-light"
            href="/"
          >
            Landing Page
          </Button>
        </Navbar.Collapse>
      </TopNavbar>
      <div className="login-container">
        <div className="login-wrapper">
          <div className="login-logo">
            <img src="/path/to/logo.png" alt="Logo" />
          </div>
          <div className="row-custom">
            <div
              className={`${
                activeTab === "login" ? "column-big" : "column-small"
              } login-padding-top`}
              style={{
                backgroundSize: "cover",
                // paddingTop: "15vh",
                backgroundImage:
                  activeTab === "login"
                    ? `linear-gradient(to bottom, rgba(0,12,23, 0.9), rgba(0,25,48, 0.95)), url(${heroGraphics})`
                    : `linear-gradient(to bottom, rgb(0,6,30, 0.86), rgba(15,55,108, .92) ), url(${heroGraphics2})`,
              }}
            >
              {activeTab === "login" ? (
                <LoginForm loginData={loginData} setLoginData={setLoginData} />
              ) : (
                <SignupPrompt handleTabChange={setActiveTab} />
              )}
            </div>
            <div
              className={activeTab === "signup" ? "column-big" : "column-small"}
              style={{
                backgroundSize: "cover",

                backgroundImage:
                  activeTab === "signup"
                    ? `linear-gradient(to bottom, rgba(0,12,23, 0.90), rgba(0,25,48, 0.98)), url(${heroGraphics})`
                    : `linear-gradient(to right, rgba(15,55,108, .86), rgb(10, 60, 131, .92)), url(${heroGraphics2})`,
              }}
            >
              {activeTab === "signup" ? (
                <SignupForm
                  signupData={signupData}
                  setSignupData={setSignupData}
                  handleSignup={handleSignup}
                  setActiveTab={setActiveTab}
                  setSuccessMessage={setSuccessMessage}
                />
              ) : (
                <LoginPrompt handleTabChange={setActiveTab} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
