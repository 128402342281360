import React, { useState } from "react";
import { Modal, Form, Button, Alert } from "react-bootstrap";

export default function CompanyForm({
  showModal,
  setShowModal,
  companyForm,
  setCompanyForm,
  createNewCompany,
}) {
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
    setError(null); // Clear error when user starts typing
  };

  const handleSubmit = async () => {
    if (!companyForm.name || !companyForm.address) {
      setError("Both name and address fields must be filled out.");
      return;
    }

    await createNewCompany();
    setCompanyForm({ name: "", address: "" }); // Reset the form
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      centered
      size="lg"
    >
      <Modal.Header
        style={{
          backgroundImage: "linear-gradient(200deg,#2b6cd5, #023e77,#001930)",
          color: "#F5EFE7",
          position: "relative",
        }}
      >
        <Modal.Title>Create a New Company</Modal.Title>
        <button
          className="custom-close-button"
          onClick={() => setShowModal(false)}
        >
          &times;
        </button>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#f0f0f0" }}>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={companyForm.name}
              onChange={handleInputChange}
              placeholder="Enter company name"
            />
          </Form.Group>
          <Form.Group style={{ marginTop: 20 }}>
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              name="address"
              value={companyForm.address}
              onChange={handleInputChange}
              placeholder="Enter company address"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer
        style={{ backgroundColor: "#f0f0f0", flexDirection: "column" }}
      >
        <Button
          variant="primary"
          style={{
            backgroundColor: "#032545",
            width: "100%",
          }}
          onClick={handleSubmit}
        >
          Create Company
        </Button>
        <Button
          variant="secondary"
          style={{ width: "100%", marginTop: "10px" }}
          onClick={() => setShowModal(false)}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
