// src/services/uploadDocumentService.js

export const generatePresignedUrlAndUpload = async (
  idToken,
  userId,
  companyName,
  fileName,
  file
) => {
  try {
    // Get the pre-signed URL from the backend
    const presignedUrlResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/documents/${userId}/${companyName}/${fileName}/generatePresignedUrl`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    if (!presignedUrlResponse.ok) {
      // Read the response stream to completion and log the text
      const errorText = await presignedUrlResponse.text();
      console.error("Error while generating pre-signed URL:", errorText);
      throw new Error(`HTTP error! status: ${presignedUrlResponse.status}`);
    }

    const { uploadUrl } = await presignedUrlResponse.json();

    // Use the pre-signed URL to upload the file directly to S3
    const uploadResponse = await fetch(uploadUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/pdf", // Ensure the content type is for PDF
      },
      body: file,
    });

    if (!uploadResponse.ok) {
      // Read the response stream to completion and log the text
      const errorText = await uploadResponse.text();
      console.error("Error during upload to S3:", errorText);
      throw new Error(
        `HTTP error during upload! status: ${uploadResponse.status}`
      );
    }

    return uploadResponse;
  } catch (error) {
    // Handle any other errors that occurred during the process
    console.error("Error in generatePresignedUrlAndUpload:", error);
    throw error; // Re-throw the error to handle it further up the call stack
  }
};
