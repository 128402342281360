import React from "react";
import { useMutation } from "react-query";
import { loginUser, getUserEmail } from "../services/userService";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch } from "react-redux";
import { login } from "../store/userSlice";

const LoginForm = ({ loginData, setLoginData, setIsLoggedIn }) => {
  const dispatch = useDispatch();

  const {
    mutate: loginUserMutation,
    isLoading,
    error,
  } = useMutation(loginUser, {
    onSuccess: (data) => {
      console.log("User logged in successfully!");
      getUserEmail(data.AuthenticationResult.AccessToken).then((userData) => {
        dispatch(
          login({
            accessToken: data.AuthenticationResult.AccessToken,
            idToken: data.AuthenticationResult.IdToken,
            refreshToken: data.AuthenticationResult.RefreshToken,
            name: userData.UserAttributes.find((attr) => attr.Name === "name")
              .Value,
            email: userData.UserAttributes.find((attr) => attr.Name === "email")
              .Value,
            userId: userData.UserAttributes.find(
              (attr) => attr.Name === "custom:employeeId"
            )?.Value,
            isOwner:
              userData.UserAttributes.find(
                (attr) => attr.Name === "custom:isOwner"
              ).Value === "true",
            accountType: userData.UserAttributes.find(
              (attr) => attr.Name === "custom:accountType"
            )?.Value, // Added the accountType attribute
          })
        );
      });
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    loginUserMutation(loginData);
  };

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <label className="login-form-label">Login to your Account:</label>
      <input
        type="email"
        placeholder="Email"
        value={loginData.email}
        onChange={(e) => setLoginData({ ...loginData, email: e.target.value })}
      />
      <input
        type="password"
        placeholder="Password"
        value={loginData.password}
        onChange={(e) =>
          setLoginData({ ...loginData, password: e.target.value })
        }
      />

      {error && (
        <div>
          <span className="error-message">
            An error occurred: {error.message}
          </span>
        </div>
      )}
      <button className="form-button" type="submit">
        {isLoading ? <Spinner animation="border" /> : "Log In"}
      </button>
    </form>
  );
};

export default LoginForm;
