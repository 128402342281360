import React from "react";
import Screen from "../components/Screen";
import DetailsCard from "../components/DetailsCard";
import { selectUserDetails } from "../store/userSlice";
import { useSelector } from "react-redux";

export default function SettingScreen() {
  const { name, email, isOwner, userId } = useSelector(selectUserDetails);

  const userData = {
    name: name,
    email: email,
    isOwner: isOwner,
    userId: userId,
  };

  return (
    <Screen title={"Setting Screen"}>
      <DetailsCard data={userData} dataType="user" />
    </Screen>
  );
}
