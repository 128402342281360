// src/services/timecardServices.js

export const createTimecard = async (idToken, timecardDetails) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/timecard`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    mode: "cors",
    body: JSON.stringify({
      body: timecardDetails,
    }),
  });

  if (!response.ok) {
    console.log("Error");
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const updateTimecard = async (
  idToken,
  timecardId,
  updatedTimecardDetails
) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/timecard/${timecardId}`,
    {
      method: "PUT", // Use PUT method for updating
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      mode: "cors",
      body: JSON.stringify({
        body: updatedTimecardDetails,
      }),
    }
  );

  if (!response.ok) {
    console.log("Error");
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};
