import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useMutation } from "react-query";
import { confirmUser } from "../services/userService";
import Spinner from "react-bootstrap/Spinner";

const VerificationModal = ({
  pin,
  setPin,
  handleCloseModal,
  signupData,
  setSuccessMessage,
  setSignupData,
  setActiveTab,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const {
    mutate: confirmUserMutation,
    isLoading,
    error,
  } = useMutation(confirmUser, {
    onSuccess: () => {
      // Clear the pin input
      setPin("");
      // Close the modal
      handleCloseModal();
      setSignupData({
        name: "",
        email: "",
        password: "",
        userType: "user",
      });
      setActiveTab("login");

      // Show success message
      setSuccessMessage(
        "User has been successfully verified! Please login to access your account"
      );
    },
    onError: (err) => {
      setErrorMessage(`There was an error verifying your account: ${err}`);
    },
  });

  const handlePinSubmit = async (e) => {
    e.preventDefault();
    try {
      // Perform pin verification actions
      console.log("Verification pin:", pin, signupData);

      // Call the confirmUser API with the confirmation code
      await confirmUserMutation({ confirmationCode: pin, newUser: signupData });
    } catch (error) {
      console.error("Error confirming user:", error);
      // Handle the error, e.g., display an error message
    }
  };

  const handleResendCode = () => {
    // Perform action to resend verification code
    console.log("Resending verification code...");
  };

  return (
    <Modal
      show={true}
      onHide={handleCloseModal}
      dialogClassName="verification-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Account Created!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Your account has been created successfully!</p>
        <p>
          Please enter the verification code that was sent to {signupData.email}
          :
        </p>
        <Form onSubmit={handlePinSubmit}>
          <Form.Group controlId="pin">
            <Form.Control
              type="text"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              placeholder="Enter PIN"
            />
          </Form.Group>
          {errorMessage && (
            <div>
              <span className="error-message">{errorMessage}</span>
            </div>
          )}
          <Button
            variant="primary"
            type="submit"
            style={{
              padding: "10px 20px",
              margin: "15px 0px 10px",
              backgroundColor: "#213555",
            }}
          >
            {isLoading ? <Spinner animation="border" /> : "Verify"}
          </Button>
        </Form>
        <Button
          variant="link"
          className="resend-code-btn"
          onClick={handleResendCode}
        >
          Resend Verification Code
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VerificationModal;
