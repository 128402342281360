import { createSlice } from "@reduxjs/toolkit";

export const timeCardSlice = createSlice({
  name: "timecard",
  initialState: {
    isActive: false,
    timeCardId: null,
    timeStamp: null,
  },
  reducers: {
    setTimeCardData: (state, action) => {
      return action.payload;
    },
  },
});

export const { setTimeCardData } = timeCardSlice.actions;

export default timeCardSlice.reducer;
