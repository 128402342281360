import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectUserDetails } from "../store/userSlice";

function TimeCardList({ timeCards }) {
  const [filter, setFilter] = useState("all"); // Initially show all time cards
  const { name } = useSelector(selectUserDetails);
  // Sort the time cards from the most recent day to the oldest day
  const sortedTimeCards = [...timeCards].sort(
    (a, b) => new Date(b.startTime) - new Date(a.startTime)
  );

  // Function to filter time cards based on the selected filter
  const filterTimeCards = (cards) => {
    const currentDate = new Date();
    switch (filter) {
      case "daily":
        return cards.filter((card) => {
          const cardDate = new Date(card.startTime);
          const isSameDay =
            currentDate.toDateString() === cardDate.toDateString();
          return isSameDay;
        });
      case "weekly":
        return cards.filter((card) => {
          const cardDate = new Date(card.startTime);
          const daysDifference =
            (currentDate - cardDate) / (1000 * 60 * 60 * 24);
          return daysDifference <= 7;
        });
      case "monthly":
        return cards.filter((card) => {
          const cardDate = new Date(card.startTime);
          const monthsDifference =
            (currentDate.getFullYear() - cardDate.getFullYear()) * 12 +
            currentDate.getMonth() -
            cardDate.getMonth();
          return monthsDifference <= 1;
        });
      case "yearly":
        return cards.filter((card) => {
          const cardDate = new Date(card.startTime);
          const yearsDifference =
            currentDate.getFullYear() - cardDate.getFullYear();
          return yearsDifference <= 1;
        });
      default:
        return cards;
    }
  };

  const filteredTimeCards = filterTimeCards(sortedTimeCards);

  // Function to format a date as "YYYY-MM-DD"
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Function to convert time card data to CSV
  const convertToCSV = () => {
    const csvData = [];
    csvData.push([
      "Timestamp Id",
      "Start Date",
      "Start Time",
      "End Date",
      "End Time",
      "Location Start Latitude",
      "Location Start Longitude",
      "Location End Latitude",
      "Location End Longitude",
      "Hours worked",
    ]);
    filteredTimeCards.forEach((timeCard) => {
      const startTime = new Date(timeCard.startTime);
      const endTime = timeCard.endTime ? new Date(timeCard.endTime) : null;
      const hoursWorked =
        endTime !== null
          ? ((endTime - startTime) / 1000 / 60 / 60).toFixed(2)
          : "N/A";

      const locationStartLatitude = timeCard.locationStart
        ? timeCard.locationStart.latitude
        : "N/A";
      const locationStartLongitude = timeCard.locationStart
        ? timeCard.locationStart.longitude
        : "N/A";

      const locationEndLatitude = timeCard.locationEnd
        ? timeCard.locationEnd.latitude
        : "N/A";
      const locationEndLongitude = timeCard.locationEnd
        ? timeCard.locationEnd.longitude
        : "N/A";

      csvData.push([
        timeCard.time_card_id,
        startTime.toLocaleDateString(),
        startTime.toLocaleTimeString(),
        endTime ? endTime.toLocaleDateString() : "N/A",
        endTime ? endTime.toLocaleTimeString() : "N/A",
        timeCard.locationStart || "N/A",
        timeCard.locationEnd || "N/A",
        hoursWorked,
      ]);
    });
    const currentDate = new Date();
    let filterName = filter;
    if (filter === "daily") {
      filterName = formatDate(currentDate);
    } else if (filter === "weekly") {
      const lastWeek = new Date(currentDate);
      lastWeek.setDate(currentDate.getDate() - 7);
      filterName = `${formatDate(lastWeek)}-${formatDate(currentDate)}`;
    } else if (filter === "monthly") {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const currentMonthName = monthNames[currentDate.getMonth()];
      filterName = `${currentMonthName}`;
    } else if (filter === "yearly") {
      filterName = `${currentDate.getFullYear()}`;
    }
    const filename = `${name}_Timecard_${filterName}.csv`; // Dynamic filename
    const csvContent = csvData.map((row) => row.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <div>
        <label>
          Filter by:{" "}
          <select value={filter} onChange={(e) => setFilter(e.target.value)}>
            <option value="all">All</option>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
          </select>
        </label>
        <Button
          className="download-button"
          variant="primary"
          onClick={convertToCSV}
        >
          Download as CSV
        </Button>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Timestamp Id</th>
            <th>Start Date</th>
            <th>Start Time</th>
            <th>End Date</th>
            <th>End Time</th>
            <th>Location Start</th>
            <th>Location End</th>
            <th>Hours worked</th>
          </tr>
        </thead>
        <tbody>
          {filteredTimeCards.map((timeCard) => {
            // Calculate hours worked
            const startTime = new Date(timeCard.startTime);
            const endTime = timeCard.endTime
              ? new Date(timeCard.endTime)
              : null;
            const hoursWorked =
              endTime !== null
                ? ((endTime - startTime) / 1000 / 60 / 60).toFixed(2)
                : "N/A";

            return (
              <tr key={timeCard.time_card_id}>
                <td>{timeCard.time_card_id}</td>
                <td>{startTime.toLocaleDateString()}</td>
                <td>{startTime.toLocaleTimeString()}</td>
                <td>{endTime ? endTime.toLocaleDateString() : "N/A"}</td>
                <td>{endTime ? endTime.toLocaleTimeString() : "N/A"}</td>
                <td>{timeCard.locationStart || "N/A"}</td>
                <td>{timeCard.locationEnd || "N/A"}</td>
                <td>{hoursWorked}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default TimeCardList;
