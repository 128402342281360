import AWS from "aws-sdk";

AWS.config.update({
  region: "us-west-2",
});

const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();

export const loginUser = async ({ email, password }) => {
  const params = {
    AuthFlow: "USER_PASSWORD_AUTH",
    ClientId: process.env.REACT_APP_CLIENT_ID,
    AuthParameters: {
      USERNAME: email,
      PASSWORD: password,
    },
  };

  return new Promise((resolve, reject) => {
    cognitoIdentityServiceProvider.initiateAuth(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
};

export const getUserEmail = async (accessToken) => {
  const params = {
    AccessToken: accessToken,
  };

  return new Promise((resolve, reject) => {
    cognitoIdentityServiceProvider.getUser(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        // console.log({ data });
        resolve(data);
        // Find the email attribute from the user attributes
        // const emailAttribute = data.UserAttributes.find(attribute => attribute.Name === 'email');
        // resolve(emailAttribute ? emailAttribute.Value : null);
      }
    });
  });
};

export const signupUser = async (newUser) => {
  const params = {
    ClientId: "1tk0ukmluqfnnhk838tr3hfppt",
    Username: newUser.email,
    Password: newUser.password,
    UserAttributes: [
      {
        Name: "email",
        Value: newUser.email,
      },
      {
        Name: "name",
        Value: newUser.name,
      },
      {
        Name: "custom:isOwner",
        Value:
          newUser.accountType === "business" && newUser.userType === "owner"
            ? "true"
            : "false",
      },

      {
        Name: "custom:employeeId",
        Value:
          newUser.accountType === "business" && newUser.userType === "user"
            ? newUser.employeeId
            : "",
      },

      {
        Name: "custom:accountType",
        Value: newUser.accountType,
      },
    ],
  };

  return new Promise((resolve, reject) => {
    cognitoIdentityServiceProvider.signUp(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
};

export const confirmUser = async ({ confirmationCode, newUser }) => {
  const params = {
    ClientId: "1tk0ukmluqfnnhk838tr3hfppt",
    ConfirmationCode: confirmationCode,
    Username: newUser.email,
  };

  return new Promise((resolve, reject) => {
    cognitoIdentityServiceProvider.confirmSignUp(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
};
