import React from "react";
import { Table, Card, Button } from "react-bootstrap";
import html2pdf from "html2pdf.js";
import logo from "../images/logos/logo-alt2.png";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import LoadingScreen from "./LoadingScreen";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const formatDate = (dateString, format) => {
  const date = new Date(dateString);

  return format === "fileName"
    ? `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`
    : `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

const PayrollReportTable = ({ report, startDate, endDate, companyName }) => {
  // const [logoBase64, setLogoBase64] = React.useState("");
  const [isGeneratingPDF, setIsGeneratingPDF] = React.useState(false);

  React.useEffect(() => {
    const convertImageToBase64 = (url, callback) => {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onload = () => callback(reader.result);
          reader.onerror = (error) =>
            console.log("Error converting image:", error);
          reader.readAsDataURL(blob);
        });
    };

    // convertImageToBase64(logo, setLogoBase64);
  }, []);

  // Added companyName prop
  if (!report || report.length === 0)
    return (
      <Card className="mt-3">
        <Card.Body>No report data available</Card.Body>
      </Card>
    );

  const convertToCSV = (report) => {
    const title = "Payroll Report";
    const subtitleCompany = `Company: ${companyName}`;
    const subtitlePayPeriod = `Pay Period: ${formatDate(
      startDate
    )} to ${formatDate(endDate)} Pay Period`;

    const header = ["Employee Name", "Total Hours"];
    const rows = report.map((item) => [item.employeeName, item.totalHours]);

    let csvContent = title + "\n";
    csvContent += subtitleCompany + "\n";
    csvContent += subtitlePayPeriod + "\n\n";
    csvContent += header.join(",") + "\n";
    csvContent += rows.map((e) => e.join(",")).join("\n");

    return csvContent;
  };

  const downloadCSV = (report) => {
    const csvContent = convertToCSV(report);
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `payroll_report_${companyName}_${formatDate(
      startDate,
      "fileName"
    )}_to_${formatDate(endDate, "fileName")}.csv`;
    a.click();
  };

  const downloadPDF = () => {
    setIsGeneratingPDF(true);

    const title = `${companyName} Payroll Report`;
    const subtitlePayPeriod = `Pay Period: ${formatDate(
      startDate
    )} to ${formatDate(endDate)} Pay Period`;

    const header = ["Employee Name", "Total Hours"];
    const rows = report.map((item) => [
      item.employeeName,
      item.totalHours.toString(),
    ]); // Convert numbers to strings for pdfmake

    const documentDefinition = {
      content: [
        // {
        //   stack: [
        //     {
        //       image: logoBase64,
        //       width: 100,
        //     },
        //   ],
        //   alignment: "right",
        // },
        { text: title, fontSize: 18, bold: true, margin: [0, 0, 0, 8] },
        { text: subtitlePayPeriod, fontSize: 14, margin: [0, 0, 0, 16] },
        {
          table: {
            headerRows: 1,
            widths: ["*", "*"],
            body: [header, ...rows],
          },
        },
      ],
    };

    pdfMake
      .createPdf(documentDefinition)
      .download(
        `payroll_report_${companyName}_${formatDate(
          startDate,
          "fileName"
        )}_to_${formatDate(endDate, "fileName")}.pdf`,
        () => {
          setIsGeneratingPDF(false); // PDF generation finished
        }
      );
  };

  return isGeneratingPDF ? (
    <LoadingScreen loadingText={"Generating PDF please wait..."} />
  ) : (
    <>
      <Button
        variant="primary"
        className="button-primary time-period-button"
        onClick={() => downloadCSV(report)}
      >
        Download as CSV
      </Button>
      <Button
        variant="secondary"
        className="button-primary time-period-button"
        onClick={downloadPDF}
      >
        Download as PDF
      </Button>

      <Card className="mt-3">
        <Card.Body>
          <Card.Title>
            <span className="pdf-header-alt">{companyName} </span>
            <span className="pdf-header">Payroll Report</span>
          </Card.Title>
          <hr />

          <Card.Subtitle>
            <span className="pdf-subtitle">Pay Period: </span>{" "}
            {formatDate(startDate)} to {formatDate(endDate)}
          </Card.Subtitle>
          <hr />

          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Employee Name</th>
                <th>Total Hours</th>
              </tr>
            </thead>
            <tbody>
              {report.map((item, index) => (
                <tr key={index}>
                  <td>{item.employeeName}</td>
                  <td>{item.totalHours}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default PayrollReportTable;
