import React, { useState, useEffect } from "react";
// import "../styles/Styles.css";

function StaticFooter() {
  return (
    <footer id="footer" class="bg-one footer">
      <div class="container">
        <div class="row wow fadeInUp" data-wow-duration="500ms">
          <div class="col-md-3">
            <div class="footerArea" id="footerArea1">
              <h3 align="left">About Company</h3>
              <p align="left">
                Acctech LLC is a small public accounting firm motivated to
                provide accounting, payroll and income tax service to the small
                businesses in greater Minneapolis and St Paul area.{" "}
              </p>
              <p align="left">
                <a href="#" title="Terms of Use">
                  Terms of Use
                </a>
                <br />
                <a href="#" title="Privacy Policy">
                  Privacy Policy
                </a>
                <br />
                <a href="#" title="FAQ">
                  FAQ
                </a>
                <br />
                <a href="#" title="Sitemap">
                  Sitemap
                </a>
              </p>
            </div>
          </div>

          <div class="col-md-3">
            <div class="footerArea" id="footerArea2">
              <h3>Recent Tweets</h3>
              <p>
                <a href="https://twitter.com/acctechllc/status/527869489736867840">
                  Beware of Fake IRS Emails and Phone Calls
                </a>
                <br />
                <span style={{ textTransform: "none" }}>2 hours ago</span>
              </p>
              <p>
                <a href="https://twitter.com/acctechllc/status/354874640927162369">
                  News in SunThisWeek: New Rosemount firm to focus on small
                  businesses:
                </a>
                <br />
                <span style={{ textTransform: "none" }}>6 hours ago</span>
              </p>
              <p>
                <a href="https://twitter.com/acctechllc/status/354873446854963200">
                  Rosemount Townpages News: New Rosemount business will balance
                  your books:
                </a>
                <br />
                <span style={{ textTransform: "none" }}>19 hours ago</span>
              </p>
              <p>
                <a href="https://twitter.com/acctechllc" title="">
                  VIEW ALL POSTS
                </a>
              </p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="footerArea" id="footerArea3">
              <h3>Services</h3>
              <p>
                Do you have a business? Do you want to run it more efficiently?
                We can help you in saving money by running your business in a
                more cost effective way. We can help you in keeping your
                financial record, running payroll, and filing taxes in a very
                competitive price.
              </p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="footerArea" id="footerArea4">
              <h3>Get in Touch</h3>
              <p
                style={{
                  fontWeight: "bold",
                  padding: 0,
                  marginBottom: "15px",
                  marginTop: "15px",
                }}
              >
                Main Office:
              </p>
              <div class="contact-details">
                <p style={{ padding: 0, margin: 0 }}>
                  <i class="tf-ion-ios-telephone-outline"></i>
                  <span style={{ marginLeft: "5px" }}>
                    Phone: (612) 293-7657
                  </span>
                </p>
                <p style={{ padding: 0, margin: 0 }}>
                  <i class="tf-ion-ios-email-outline"></i>
                  <span style={{ marginLeft: "5px" }}>
                    Email: info@acctechllc.com
                  </span>
                </p>
              </div>
              <div>
                <p
                  style={{
                    fontWeight: "bold",
                    padding: 0,
                    marginBottom: "15px",
                    marginTop: "15px",
                  }}
                >
                  Lansdale, PA Office:
                </p>
                <p style={{ padding: 0, margin: 0 }}>
                  <i class="tf-ion-ios-person-outline"></i>
                  <span style={{ marginLeft: "5px" }}>
                    Representive: Nura Ahmed
                  </span>
                </p>

                <p style={{ padding: 0, margin: 0 }}>
                  <i class="tf-map-pin"></i>
                  <span style={{ marginLeft: "5px" }}>
                    650 N Cannon Ave, Lansdale, PA 19446
                  </span>
                </p>

                <p style={{ padding: 0, margin: 0 }}>
                  <i class="tf-ion-ios-telephone-outline"></i>
                  <span style={{ marginLeft: "5px" }}>
                    Phone: (612) 293-7657
                  </span>
                </p>
                <p style={{ padding: 0, margin: 0 }}>
                  <i class="tf-ion-ios-email-outline"></i>
                  <span style={{ marginLeft: "5px" }}>
                    Email: info@acctechllc.com
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end row --> */}
      </div>
      {/* <!-- end container --> */}
    </footer>
  );
}

export default StaticFooter;
