import React, { useState, useEffect } from "react";

function LoadingScreen({ loadingText }) {
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    // Assuming you want to show the loader for 5 seconds, then fade out
    const timer = setTimeout(() => {
      setFadeOut(true);
    }, 1000); // 5000ms = 5s

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  return (
    <div className={`loader ${fadeOut ? "fade-out" : ""}`}>
      <div className="loading-text">
        {loadingText ? loadingText : "Loading..."}
      </div>
      <svg
        className="waves"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#2b6cd5"
          fill-opacity="1"
          d="M0,128L60,149.3C120,171,240,213,360,208C480,203,600,149,720,144C840,139,960,181,1080,181.3C1200,181,1320,139,1380,117.3L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        ></path>
        <path
          fill="#003366"
          fill-opacity="1"
          d="M0,256L60,245.3C120,235,240,213,360,213.3C480,213,600,235,720,224C840,213,960,171,1080,154.7C1200,139,1320,149,1380,154.7L1440,160L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        ></path>
      </svg>
    </div>
  );
}

export default LoadingScreen;
