import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectTokens, selectUserDetails, logout } from "../store/userSlice";
import ErrorModal from "../components/ErrorModal";

import { Link } from "react-router-dom";

export default function PersonalLandingScreen() {
  const { idToken } = useSelector(selectTokens);
  const { userId } = useSelector(selectUserDetails);

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <ErrorModal
        continueButton={() => {
          if (error !== "User location is not available.") {
            handleLogout();
          }
          setError(false);
          setShow(false);
        }}
        show={show}
        message={
          error === "User location is not available."
            ? {
                title: "Location not found",
                body: `Please try again. Error message: ${error}`,
                buttonText: "Continue",
              }
            : {
                title: `${
                  error?.message === "HTTP error! status: 401"
                    ? "Session expired!"
                    : error
                }`,
                body: `Please try logging in again. ${
                  error?.message === "HTTP error! status: 401"
                    ? ""
                    : `Error message: ${error}`
                }`,
                buttonText: "Continue",
              }
        }
      />
      <div className="landing-text-container">
        All notifications and announcements related to Personal Tax will show
        here.
      </div>
      <Link
        state={{ companyData: { name: "personal" } }}
        to="/portal/documents/viewDocs"
      >
        <button className="primary-button">Manage Documents</button>
      </Link>
    </>
  );
}
