import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FaMoneyBillWave, FaFileInvoiceDollar, FaClock } from "react-icons/fa";
import styled, { keyframes } from "styled-components";
import { bounceInUp } from "react-animations";
import payrollGraphic from "../../images/graphics/payroll3.png";
import timeGraphic from "../../images/graphics/time2.png";
import taxGraphic from "../../images/graphics/tax.png";

const BounceInUpAnimation = keyframes`${bounceInUp}`;
const AnimatedCard = styled(Card)`
  opacity: 0.5;

  &.animate {
    animation: 0.5s ${BounceInUpAnimation};
    opacity: 1;
  }
`;

function StaticFeaturesSection() {
  const [inViewRef, inView] = useInView({
    threshold: 0.1,
  });

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (inView) {
      setAnimate(true);
    } else {
      setAnimate(false);
    }
  }, [inView]);

  return (
    <section id="feature" className="bg-one  static-section" ref={inViewRef}>
      <Container class="container">
        <div class="title text-center wow fadeIn" data-wow-duration="1500ms">
          <h2>
            <span className="darkTitle"> Key </span>
            <span className="lightTitle">Features</span>
          </h2>
          <div class="borderLight"></div>
        </div>

        {/* <p>
          Experience comprehensive solutions tailored for your business needs.
        </p> */}
        <Row>
          {/* Payroll Service Card */}
          <Col sm={4}>
            <AnimatedCard
              className={`service-card ${
                animate ? "animate" : ""
              } feature-card`}
            >
              <Card.Img variant="top" src={payrollGraphic} alt="Payroll" />

              <Card.Body>
                <Card.Title>Payroll Service</Card.Title>
                <Card.Text>
                  Seamless payroll solutions for businesses. Create your
                  business profile, add employees, and manage time logs
                  effortlessly.
                </Card.Text>
                <Button variant="primary" href="/portal">
                  Get Started
                </Button>
              </Card.Body>
            </AnimatedCard>
          </Col>

          {/* Employee Clock-in Card */}
          <Col sm={4}>
            <AnimatedCard
              className={`service-card ${
                animate ? "animate" : ""
              } feature-card`}
            >
              <Card.Img
                variant="top"
                src={timeGraphic}
                alt="Employee Time Tracking"
              />

              <Card.Body>
                <Card.Title>Employee Time Tracking</Card.Title>
                <Card.Text>
                  Employees can clock in and out with ease. Employers can view
                  time logs and generate payroll reports seamlessly.
                </Card.Text>
                <Button variant="primary" href="/portal">
                  Get Started
                </Button>
              </Card.Body>
            </AnimatedCard>
          </Col>

          {/* Tax Services Card */}
          <Col sm={4}>
            <AnimatedCard
              className={`service-card ${
                animate ? "animate" : ""
              } feature-card`}
            >
              <Card.Img variant="top" src={taxGraphic} alt="Tax" />

              <Card.Body>
                <Card.Title>Tax Services</Card.Title>
                <Card.Text>
                  Simplify tax filing. Upload and remotely sign your tax
                  documents with our secure platform.
                </Card.Text>
                <Button variant="primary" href="/portal">
                  Get Started
                </Button>
              </Card.Body>
            </AnimatedCard>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default StaticFeaturesSection;
