// StaticServicesPage.js

import React, { useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FaMoneyBillWave, FaFileInvoiceDollar, FaClock } from "react-icons/fa";
import companyGrow from "../../images/graphics/company-growth.png";

export default function StaticServicesPage() {
  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(null);

  const handleCollapsibleClick = (name) => {
    if (name === isCollapsibleOpen) {
      setIsCollapsibleOpen(null);
    } else {
      setIsCollapsibleOpen(name);
    }
  };
  return (
    <>
      <section id="services" className="bg-one section">
        <div className="container">
          <div className="row">
            {/* <!-- section title --> */}
            <div
              className="title text-center wow fadeIn"
              data-wow-duration="500ms"
            >
              <h2>
                <span className="darkTitle"> Our </span>
                <span className="lightTitle">Services</span>
              </h2>
              <div className="borderLight"></div>
            </div>
            <div className="newSection">
              {/* <!-- Start of Accounting services --> */}
              <button
                onClick={() => handleCollapsibleClick("cpa")}
                className="collapsible"
              >
                <h3 style={{ color: "#fff" }}>CPA Services</h3>
              </button>
              <div
                className={
                  isCollapsibleOpen === "cpa"
                    ? "collapaibleContentOpen row"
                    : "collapaibleContent row"
                }
              >
                {/* <!-- /section title -->

              <!-- Single Service Item --> */}
                <article
                  className="col-md-4 col-sm-6 col-xs-12 wow fadeInUp"
                  data-wow-duration="500ms"
                >
                  <div className="service-block text-center">
                    <div className="service-icon text-center">
                      <i
                        style={{ color: "#001a2f" }}
                        className="fa fa-calculator"
                      ></i>
                    </div>
                    <h3>Accounting Services</h3>
                    <p>
                      Besides our wholesale bookkeeping service we also offer
                      retail bookkeeping. We offer full service accounting for
                      our retail customers; which includes creating invoice for
                      our clients and send to their ..
                    </p>
                    <a href="#contact" className="newButton">
                      Learn More
                    </a>
                  </div>
                </article>
                {/* <!-- End Single Service Item -->

              <!-- Single Service Item --> */}
                <article
                  className="col-md-4 col-sm-6 col-xs-12 wow fadeInUp"
                  data-wow-duration="500ms"
                  data-wow-delay="200ms"
                >
                  <div className="service-block text-center">
                    <div className="service-icon text-center">
                      <i
                        style={{ color: "#001a2f" }}
                        className="fa fa-coins"
                      ></i>
                    </div>
                    <h3>Payroll Services</h3>
                    <p>
                      Running payroll on-time and issue paycheck to employees is
                      very crucial. We do timely and accurately process payroll,
                      printing check or send direct deposit either through our
                      in-house payroll ..
                    </p>
                    <a href="#contact" className="newButton">
                      Learn More
                    </a>
                  </div>
                </article>
                {/* <!-- End Single Service Item -->

              <!-- Single Service Item --> */}
                <article
                  className="col-md-4 col-sm-6 col-xs-12 wow fadeInUp"
                  data-wow-duration="500ms"
                  data-wow-delay="400ms"
                >
                  <div className="service-block text-center">
                    <div className="service-icon text-center">
                      <i
                        style={{ color: "#001a2f" }}
                        className="fa fa-money-bill-alt"
                      ></i>
                    </div>
                    <h3>Income Tax</h3>
                    <p>
                      Acctech LLC is an IRS Authorized E-File Provider in the
                      community and specialized in business taxes. We use enough
                      research and time to maximum tax return through accurate
                      tax filing with lowest ..
                    </p>
                    <a href="#contact" className="newButton">
                      Learn More
                    </a>
                  </div>
                </article>
                {/* <!-- End Single Service Item -->

              <!-- Single Service Item --> */}
                <article
                  className="col-md-4 col-sm-6 col-xs-12 wow fadeInUp"
                  data-wow-duration="500ms"
                  data-wow-delay="200ms"
                >
                  <div className="service-block text-center">
                    <div className="service-icon text-center">
                      <i
                        style={{ color: "#001a2f" }}
                        className="fa fa-book"
                      ></i>
                    </div>
                    <h3>Wholesale Bookkeeping</h3>
                    <p>
                      If you are an Accountant and you are struggling with your
                      huge number of clients bookkeeping or you have a big
                      business and needs bulk bookkeeping, you can use
                      our“Wholesale Bookkeeping”.
                    </p>
                    <a href="#contact" className="newButton">
                      Learn More
                    </a>
                  </div>
                </article>
                {/* <!-- End Single Service Item -->

              <!-- Single Service Item --> */}
                <article
                  className="col-md-4 col-sm-6 col-xs-12 wow fadeInUp"
                  data-wow-duration="500ms"
                  data-wow-delay="400ms"
                >
                  <div className="service-block text-center">
                    <div className="service-icon text-center">
                      <i
                        style={{ color: "#001a2f" }}
                        className="fa fa-users"
                      ></i>
                    </div>
                    <h3>Acctech CDA</h3>
                    <p>
                      Acctech is a contributry program to assist community
                      development. Contributions are made by both Acctech and
                      its customer referred by the Non-Profit
                      organizations.Whatever the referred customer contributes
                      the Organization, Acctech will match the same amount of
                      contribution.
                    </p>
                    <a href="#contact" className="newButton">
                      Learn More
                    </a>
                  </div>
                </article>
                {/* <!-- End Single Service Item -->

              <!-- Single Service Item --> */}
                <article
                  className="col-md-4 col-sm-6 col-xs-12 wow fadeInUp"
                  data-wow-duration="500ms"
                  data-wow-delay="600ms"
                >
                  <div className="service-block text-center kill-margin-bottom">
                    <div className="service-icon text-center">
                      <i
                        style={{ color: "#001a2f" }}
                        className="fa fa-business-time"
                      ></i>
                    </div>
                    <h3>Business Plan</h3>
                    <p>
                      Acctech LLC is an IRS Authorized E-File Provider in the
                      community and specialized in business taxes. We use enough
                      research and time to maximum tax return through accurate
                      tax filing with lowest ..
                    </p>
                    <a href="#contact" className="newButton">
                      Learn More
                    </a>
                  </div>
                </article>
                {/* <!-- End Single Service Item --> */}
              </div>
              {/* <!-- End of Accounting Services -->

          <!-- ================================================================================================ -->
          <!-- Start of IT services --> */}
              <button
                onClick={() => handleCollapsibleClick("tech")}
                className="collapsible"
              >
                <h3 style={{ color: "#fff" }}>Technology Services</h3>
              </button>
              <div
                className={
                  isCollapsibleOpen === "tech"
                    ? "collapaibleContentOpen row"
                    : "collapaibleContent row"
                }
              >
                {/* <!-- /section title -->

              <!-- Single Service Item --> */}
                <article
                  className="col-md-4 col-sm-6 col-xs-12 wow fadeInUp"
                  data-wow-duration="500ms"
                >
                  <div className="service-block text-center">
                    <div className="service-icon text-center">
                      <i
                        style={{ color: "#001a2f" }}
                        className="fa fa-laptop-code"
                      ></i>
                    </div>
                    <h3>Web Development</h3>
                    <p>
                      Our web design and development team are always up to date
                      with the latest trends in the digital world. This gives us
                      the ability to help our customers get the website of their
                      dream ...
                    </p>
                    <a href="#contact" className="newButton">
                      Learn More
                    </a>{" "}
                  </div>
                </article>
                {/* <!-- End Single Service Item -->

              <!-- Single Service Item --> */}
                <article
                  className="col-md-4 col-sm-6 col-xs-12 wow fadeInUp"
                  data-wow-duration="500ms"
                  data-wow-delay="200ms"
                >
                  <div className="service-block text-center">
                    <div className="service-icon text-center">
                      <i
                        style={{ color: "#001a2f" }}
                        className="fa fa-users"
                      ></i>
                    </div>
                    <h3>IT Consulting</h3>
                    <p>
                      So you got a new business but you don't know how to best
                      setup your work place to have the most productivity? Don't
                      worry, here at AccTech, we can help you with whatever IT
                      solution you need!
                    </p>
                    <a href="#contact" className="newButton">
                      Learn More
                    </a>{" "}
                  </div>
                </article>
                {/* <!-- End Single Service Item -->
              <!-- Single Service Item --> */}
                <article
                  className="col-md-4 col-sm-6 col-xs-12 wow fadeInUp"
                  data-wow-duration="500ms"
                  data-wow-delay="200ms"
                >
                  <div className="service-block text-center">
                    <div className="service-icon text-center">
                      <i
                        style={{ color: "#001a2f" }}
                        className="fa fa-cloud"
                      ></i>
                    </div>
                    <h3>Web Hosting</h3>
                    <p>
                      You have your website made but now you are looking for a
                      place to host your site. AccTech has the best deals for
                      Web Hosting that comes with free maintenance and support”.
                    </p>
                    <a href="#contact" className="newButton">
                      Learn More
                    </a>{" "}
                  </div>
                </article>
                {/* <!-- End Single Service Item -->
              <!-- Single Service Item --> */}
                <article
                  className="col-md-4 col-sm-6 col-xs-12 wow fadeInUp"
                  data-wow-duration="500ms"
                  data-wow-delay="400ms"
                >
                  <div className="service-block text-center">
                    <div className="service-icon text-center">
                      <i
                        style={{ color: "#001a2f" }}
                        className="fa fa-database"
                      ></i>
                    </div>
                    <h3>Database Management</h3>
                    <p>
                      AccTech uses AWS Cloud Services to store your data so you
                      can have the backend you need for your website ..
                    </p>
                    <a href="#contact" className="newButton">
                      Learn More
                    </a>{" "}
                  </div>
                </article>
                {/* <!-- End Single Service Item -->



              <!-- Single Service Item --> */}
                <article
                  className="col-md-4 col-sm-6 col-xs-12 wow fadeInUp"
                  data-wow-duration="500ms"
                  data-wow-delay="400ms"
                >
                  <div className="service-block text-center">
                    <div className="service-icon text-center">
                      <i
                        style={{ color: "#001a2f" }}
                        className="fa fa-mobile-alt"
                      ></i>
                    </div>
                    <h3>App Development</h3>
                    <p>
                      Here at AccTech, we are offering Android and iOS
                      application development services that will help your
                      business go to the next level!
                    </p>
                    <a href="#contact" className="newButton">
                      Learn More
                    </a>{" "}
                  </div>
                </article>
                {/* <!-- End Single Service Item -->

              <!-- Single Service Item --> */}
                <article
                  className="col-md-4 col-sm-6 col-xs-12 wow fadeInUp"
                  data-wow-duration="500ms"
                  data-wow-delay="600ms"
                >
                  <div className="service-block text-center kill-margin-bottom">
                    <div className="service-icon text-center">
                      <i
                        style={{ color: "#001a2f" }}
                        className="fa fa-hand-holding-usd"
                      ></i>
                    </div>
                    <h3>Social Media Marketing</h3>
                    <p>
                      Social Media is what rules the digital world these days,
                      and what better place to market your business than the
                      social media. Here at AccTech, we will use the hottest
                      trends in the digital world to market your services for
                      your business!
                    </p>
                    <a href="#contact" className="newButton">
                      Learn More
                    </a>{" "}
                  </div>
                </article>
                {/* <!-- End Single Service Item --> */}
              </div>
              {/* <!-- End of IT Services -->
          <!-- ================================================================================================ -->
          <!-- ================================================================================================ -->
          <!-- Start of Multimedia services --> */}
              <button
                onClick={() => handleCollapsibleClick("media")}
                className="collapsible"
              >
                <h3 style={{ color: "#fff" }}>Multimedia Services</h3>
              </button>
              <div
                className={
                  isCollapsibleOpen === "media"
                    ? "collapaibleContentOpen row"
                    : "collapaibleContent row"
                }
              >
                {/* <!-- /section title -->

              <!-- Single Service Item --> */}
                <article
                  className="col-md-4 col-sm-6 col-xs-12 wow fadeInUp"
                  data-wow-duration="500ms"
                >
                  <div className="service-block text-center">
                    <div className="service-icon text-center">
                      <i
                        style={{ color: "#001a2f" }}
                        className="fa fa-camera"
                      ></i>
                    </div>
                    <h3>Photo Services</h3>
                    <p>
                      AccTech provides our customers with porfessional stock
                      photos, photo editing and photography ..
                    </p>
                    <a href="#contact" className="newButton">
                      Learn More
                    </a>{" "}
                  </div>
                </article>
                {/* <!-- End Single Service Item -->

              <!-- Single Service Item --> */}
                <article
                  className="col-md-4 col-sm-6 col-xs-12 wow fadeInUp"
                  data-wow-duration="500ms"
                  data-wow-delay="200ms"
                >
                  <div className="service-block text-center">
                    <div className="service-icon text-center">
                      <i
                        style={{ color: "#001a2f" }}
                        className="fa fa-video"
                      ></i>
                    </div>
                    <h3>Video Services</h3>
                    <p>
                      Here at AccTechkl, we provide our customers with video
                      editing and video advertisement for their business!
                    </p>
                    <a href="#contact" className="newButton">
                      Learn More
                    </a>{" "}
                  </div>
                </article>
                {/* <!-- End Single Service Item -->

              <!-- Single Service Item --> */}
                <article
                  className="col-md-4 col-sm-6 col-xs-12 wow fadeInUp"
                  data-wow-duration="500ms"
                  data-wow-delay="400ms"
                >
                  <div className="service-block text-center">
                    <div className="service-icon text-center">
                      <i
                        style={{ color: "#001a2f" }}
                        className="fa fa-print"
                      ></i>
                    </div>
                    <h3>Printable Advertisements</h3>
                    <p>
                      AccTech provides our customers with many types of
                      advertisements to help their business succeed. We offer
                      brouchures, banners and flyers ..
                    </p>
                    <a href="#contact" className="newButton">
                      Learn More
                    </a>{" "}
                  </div>
                </article>
                {/* <!-- End Single Service Item -->


              <!-- End of Multimedia Services --> */}
              </div>
              {/* <!-- End row --> */}
            </div>
          </div>
        </div>
      </section>

      <section
        id="team-skills"
        class="parallax-section section section-bg overly"
      >
        <div class="container">
          <div class="row">
            {/* <!-- section title --> */}
            <div class="col-md-12">
              <div class="title text-center">
                <h2>
                  Our
                  <span class="lightTitle"> Skills</span>
                </h2>
                <div class="border"></div>
              </div>
            </div>
            {/* <!-- /section title --> */}
          </div>
          {/* <!-- End row --> */}
          <div class="row">
            <div class="col-md-6">
              <h2>
                We’ve skilled in wide range of web and
                <br /> Other digital tools along with accounting services.
              </h2>
              <p>
                Our company is growing each year and many difrrent services are
                being added so we can help you with whatever our customers are
                in need of.
              </p>
              <img width={"100%"} src={companyGrow} alt="" />
            </div>
            <div class="col-md-6">
              <ul class="skill-bar">
                <li>
                  <p>
                    <span>01-</span> Accounting Services
                  </p>
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      aria-valuenow="70"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: "90%" }}
                    ></div>
                  </div>
                </li>
                <li>
                  <p>
                    <span>02-</span>Web Development{" "}
                  </p>
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      aria-valuenow="70"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: "90%" }}
                    ></div>
                  </div>
                </li>
                <li>
                  <p>
                    <span>03-</span> Graphic Design
                  </p>
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      aria-valuenow="70"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: "90%" }}
                    ></div>
                  </div>
                </li>
                <li>
                  <p>
                    <span>04-</span> Mobile Development
                  </p>
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      aria-valuenow="70"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: "84%" }}
                    ></div>
                  </div>
                </li>
                <li>
                  <p>
                    <span>05-</span> IT Services
                  </p>
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      aria-valuenow="70"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: "80%" }}
                    ></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <!-- End container --> */}
      </section>
    </>
  );
}
