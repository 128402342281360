// DetailsCard.js
import React from "react";
import { Card, Accordion, Button } from "react-bootstrap";
import {
  FaMapMarkerAlt,
  FaIdCard,
  FaClock,
  FaUser,
  FaEnvelope,
} from "react-icons/fa";

const DetailsCard = ({ data, dataType, hide }) => {
  return (
    <Accordion defaultActiveKey={hide ? "1" : "0"}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <span style={{ fontWeight: "bold" }}>
            {dataType === "company"
              ? "Company Details"
              : dataType === "employee"
              ? "Employee Details"
              : "User Details"}
          </span>
        </Accordion.Header>
        <Accordion.Body>
          <Card>
            <Card.Body>
              <Card.Title>
                {dataType === "company"
                  ? "Company Details"
                  : dataType === "employee"
                  ? "Employee Details"
                  : "User Details"}
              </Card.Title>
              {dataType === "company" && (
                <>
                  <Card.Text>
                    <FaMapMarkerAlt /> <strong>Address:</strong> {data?.address}
                  </Card.Text>
                  <Card.Text>
                    <FaIdCard /> <strong>Company ID:</strong> {data?.company_id}
                  </Card.Text>
                  <Card.Text>
                    <FaUser /> <strong>User ID:</strong> {data?.user_id}
                  </Card.Text>
                </>
              )}
              {dataType === "employee" && (
                <>
                  <Card.Text>
                    <FaIdCard /> <strong>Employee ID:</strong>{" "}
                    {data?.employee_id}
                  </Card.Text>
                  <Card.Text>
                    <FaUser /> <strong>First Name:</strong> {data?.firstName}
                  </Card.Text>
                  <Card.Text>
                    <FaUser /> <strong>Last Name:</strong> {data?.lastName}
                  </Card.Text>
                  <Card.Text>
                    <FaMapMarkerAlt /> <strong>Position:</strong>{" "}
                    {data?.position}
                  </Card.Text>
                </>
              )}
              {dataType === "user" && (
                <>
                  <Card.Text>
                    <FaUser /> <strong>Name:</strong> {data?.name}
                  </Card.Text>
                  <Card.Text>
                    <FaEnvelope /> <strong>Email:</strong> {data?.email}
                  </Card.Text>

                  {data.userId ? (
                    <Card.Text>
                      <FaUser /> <strong>User ID:</strong> {data?.userId}
                    </Card.Text>
                  ) : null}

                  <Card.Text>
                    <FaUser /> <strong>Account Type:</strong>{" "}
                    {data?.isOwner ? "Owner" : "Employee"}
                  </Card.Text>
                </>
              )}
              {data.createdAt ? (
                <Card.Text>
                  <FaClock /> <strong>Created At:</strong>{" "}
                  {new Date(data?.createdAt).toLocaleString()}
                </Card.Text>
              ) : null}
              {data.updatedAt ? (
                <Card.Text>
                  <FaClock /> <strong>Updated At:</strong>{" "}
                  {new Date(data?.updatedAt).toLocaleString()}
                </Card.Text>
              ) : null}
            </Card.Body>
          </Card>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default DetailsCard;
