import { useState } from "react";
import Screen from "../components/Screen";
import Companies from "./Companies";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ErrorModal from "../components/ErrorModal";
import { useDispatch } from "react-redux";
import { logout, selectUserDetails } from "../store/userSlice";
import { useSelector } from "react-redux";
import OwnerLandingScreen from "./OwnerLandingScreen";
import WorkerLandingScreen from "./WorkerLandingScreen";
import PersonalLandingScreen from "./PersonalLandingScreen";

export default function LandingScreen() {
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);
  const userDetails = useSelector(selectUserDetails);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Screen title={`Welcome ${userDetails.email}`}>
      <ErrorModal
        continueButton={() => {
          handleLogout();
          setError(false);
          setShow(false);
        }}
        show={show}
        message={{
          title: `${
            error === "HTTP error! status: 401" ? "Session expired!" : error
          }`,
          body: `Please try logging in again. ${
            error === "HTTP error! status: 401" ? "" : `Error message: ${error}`
          }`,
          buttonText: "Continue",
        }}
      />
      {userDetails.isOwner ? (
        <OwnerLandingScreen setError={setError} setShow={setShow} />
      ) : userDetails.accountType === "personal" ? (
        <PersonalLandingScreen />
      ) : (
        <WorkerLandingScreen />
      )}
    </Screen>
  );
}
