export const getDocumentsByCompany = async (idToken, userId, companyName) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/documents/${userId}/${companyName}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        mode: "cors",
      }
    );

    if (!response.ok) {
      const errorBody = await response.text();
      console.error("Error response body:", errorBody);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // Log the error for debugging purposes
    console.error("Error fetching documents:", error);
    throw error;
  }
};
