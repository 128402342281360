import React, { useState, useEffect } from "react";
// import "../styles/Styles.css";

function StaticContact() {
  return (
    <section id="contact" class="contact-us section-bg">
      <div class="container">
        <div class="row">
          {/* <!-- section title --> */}
          <div class="title text-center wow fadeIn" data-wow-duration="500ms">
            <h2>
              <span class="darkTitle">Get In</span>
              <span class="lightTitle"> Touch</span>
            </h2>
            <div class="borderLight"></div>
          </div>
          {/* <!-- /section title -->

				<!-- Contact Details --> */}
          <div
            style={{ paddingBottom: "50px" }}
            class="contact-info col-md-6 wow fadeInUp"
            data-wow-duration="500ms"
          >
            <h3>Contact Details:</h3>
            {/* <!-- <p>
						Here at Acctech, we take careful
						steps to make sure your experience 
						with us as professional and smooth as possible.
				</p> --> */}

            <div class="contact-details">
              <p
                style={{
                  fontWeight: "bold",
                  padding: 0,
                  marginBottom: "15px",
                  marginTop: "15px",
                }}
              >
                Main Office (Remote):
              </p>

              <p style={{ padding: 0, margin: 0, textDecoration: "underline" }}>
                <i class="tf-map-pin"></i>
                <span style={{ marginLeft: "5px" }}>Mailing Address:</span>
              </p>

              <p style={{ padding: 0, margin: 0 }}>
                <i class="tf-map-pin"></i>
                <span style={{ marginLeft: "5px" }}>
                  1960 Cliff Lake Rd Ste 129, Eagan, MN 55122
                </span>
              </p>

              <p style={{ padding: 0, margin: 0 }}>
                <i class="tf-ion-ios-telephone-outline"></i>
                <span style={{ marginLeft: "5px" }}>Phone: (612) 293-7657</span>
              </p>
              <p style={{ padding: 0, margin: 0 }}>
                <i class="tf-ion-ios-email-outline"></i>
                <span style={{ marginLeft: "5px" }}>
                  Email: info@acctechllc.com
                </span>
              </p>
            </div>
            <div>
              <p
                style={{
                  fontWeight: "bold",
                  padding: 0,
                  marginBottom: "15px",
                  marginTop: "15px",
                }}
              >
                Lansdale, PA Office:
              </p>
              <p style={{ padding: 0, margin: 0 }}>
                <i class="tf-ion-ios-person-outline"></i>
                <span style={{ marginLeft: "5px" }}>
                  Representive: Nura Ahmed
                </span>
              </p>

              <p style={{ padding: 0, margin: 0, textDecoration: "underline" }}>
                <i class="tf-map-pin"></i>
                <span style={{ marginLeft: "5px" }}>Office Address:</span>
              </p>

              <p style={{ padding: 0, margin: 0 }}>
                <i class="tf-map-pin"></i>
                <span style={{ marginLeft: "5px" }}>
                  650 N Cannon Ave, Lansdale, PA 19446
                </span>
              </p>

              <p style={{ padding: 0, margin: 0 }}>
                <i class="tf-ion-ios-telephone-outline"></i>
                <span style={{ marginLeft: "5px" }}>Phone: (612) 293-7657</span>
              </p>
              <p style={{ padding: 0, margin: 0 }}>
                <i class="tf-ion-ios-email-outline"></i>
                <span style={{ marginLeft: "5px" }}>
                  Email: info@acctechllc.com
                </span>
              </p>
            </div>
          </div>
          {/* <!-- / End Contact Details --> */}

          <div
            class="contact-form col-md-6 wow fadeInUp"
            data-wow-duration="500ms"
            data-wow-delay="300ms"
          >
            <div class="mapouter">
              <div class="gmap_canvas">
                <iframe
                  width="100%"
                  height="300px"
                  id="gmap_canvas"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2831.3990935598576!2d-93.20776982315718!3d44.793054671070905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87f631c44bd81313%3A0xd27fa8918a99e8f1!2s1960%20Cliff%20Lake%20Rd%2C%20Eagan%2C%20MN%2055122!5e0!3m2!1sen!2sus!4v1720737041808!5m2!1sen!2sus"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
                <a href="https://www.crocothemes.net"></a>
              </div>
              <style>
                {/* .mapouter {
					text-align: right;
					height: 500px;
					width: 100%;
				}

				.gmap_canvas {
					overflow: hidden;
					background: none !important;
					height: 500px;
					width: 100%;
				} */}
              </style>
            </div>
          </div>
        </div>
        {/* <!-- end row --> */}
      </div>
      {/* <!-- end container --> */}

      {/* <!-- Google Map --> */}
      {/* <!-- /Google Map --> */}
    </section>
  );
}

export default StaticContact;
