import React, { useState } from "react";
import { useMutation } from "react-query";
import { signupUser } from "../services/userService";
import VerificationModal from "./VerificationModal";
import Spinner from "react-bootstrap/Spinner";

const SignupForm = ({
  signupData,
  setSignupData,
  setActiveTab,
  setSuccessMessage,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [registrationKey, setRegistrationKey] = useState(""); // New state for registration key
  const [registrationKeyError, setRegistrationKeyError] = useState("");
  const [accountType, setAccountType] = useState("personal");

  const [pin, setPin] = useState("");

  const {
    mutate: signupUserMutation,
    isLoading,
    error,
  } = useMutation(signupUser, {
    onSuccess: () => {
      console.log("User signed up successfully!");
      setShowModal(true);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (registrationKey === "acctech538") {
      signupUserMutation(signupData);
      setRegistrationKeyError(""); // Clear any previous error message
    } else {
      setRegistrationKeyError("Invalid or missing registration key.");
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setPin("");
  };

  return (
    <>
      <form className="signup-form" onSubmit={handleSubmit}>
        <label className="login-form-label">Sign up to get started:</label>

        <input
          type="text"
          placeholder="Full Name"
          value={signupData.name}
          onChange={(e) =>
            setSignupData({ ...signupData, name: e.target.value })
          }
        />
        <input
          type="email"
          placeholder="Email"
          value={signupData.email}
          onChange={(e) =>
            setSignupData({ ...signupData, email: e.target.value })
          }
        />
        <input
          type="password"
          placeholder="Password"
          value={signupData.password}
          onChange={(e) =>
            setSignupData({ ...signupData, password: e.target.value })
          }
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={signupData.confirmPassword}
          onChange={(e) =>
            setSignupData({ ...signupData, confirmPassword: e.target.value })
          }
        />

        <select
          value={accountType}
          onChange={(e) => {
            setAccountType(e.target.value);
            setSignupData({ ...signupData, accountType: e.target.value });
          }}
        >
          <option value="personal">Personal</option>
          <option value="business">Business</option>
        </select>

        {accountType === "business" && (
          <>
            <select
              value={signupData.userType}
              onChange={(e) =>
                setSignupData({ ...signupData, userType: e.target.value })
              }
            >
              <option value="user">User</option>
              <option value="owner">Owner</option>
            </select>
            {accountType === "business" && signupData.userType === "user" && (
              <input
                type="text"
                placeholder="Employee ID"
                value={signupData.employeeId || ""}
                onChange={(e) =>
                  setSignupData({ ...signupData, employeeId: e.target.value })
                }
              />
            )}
          </>
        )}
        <input
          type="text"
          placeholder="Registration Key"
          value={registrationKey}
          onChange={(e) => setRegistrationKey(e.target.value)}
        />
        {isLoading && <div>Loading...</div>}
        {registrationKeyError && (
          <div>
            <span className="error-message">{registrationKeyError}</span>
          </div>
        )}
        {error && (
          <div>
            <span className="error-message">
              An error occurred: {error.message}
            </span>
          </div>
        )}
        <button className="form-button" type="submit">
          {isLoading ? <Spinner animation="border" /> : "Sign Up"}
        </button>
      </form>

      {showModal && (
        <VerificationModal
          pin={pin}
          setPin={setPin}
          handleCloseModal={handleCloseModal}
          signupData={signupData}
          setSuccessMessage={setSuccessMessage}
          setSignupData={setSignupData}
          setActiveTab={setActiveTab}
        />
      )}
    </>
  );
};

export default SignupForm;
