import React from "react";
import { Link } from "react-router-dom";
import { ListGroup, Spinner } from "react-bootstrap";
import { FaUser } from "react-icons/fa";

export default function EmployeeList({ employees, isLoading }) {
  // If the data is still loading
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  // If there are no employees
  if (employees?.length === 0) {
    return (
      <div style={{ marginTop: "20px" }}>
        There are no employees added. Please add employees.
      </div>
    );
  }

  return (
    <div>
      <ListGroup>
        {employees?.map((employee) => (
          <Link
            to={`/portal/employee/${employee.employee_id}`}
            state={{ employeeData: employee }}
            style={{ textDecoration: "none" }}
          >
            <ListGroup.Item
              key={employee.employee_id}
              action
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <FaUser style={{ marginRight: "10px", color: "#023e77" }} />
                <div
                  style={{
                    textDecoration: "none",
                    color: "#213555",
                    fontWeight: 500,
                    marginLeft: 20,
                  }}
                >
                  {employee.firstName} {employee.lastName}
                </div>
              </div>
              <div
                to={`/portal/employee/${employee.employee_id}`}
                state={{ employeeData: employee }}
                style={{ color: "#213555" }}
              >
                Edit
              </div>
            </ListGroup.Item>
          </Link>
        ))}
      </ListGroup>
    </div>
  );
}
