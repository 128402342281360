import React from "react";
import { Row, Col, Button, Container } from "react-bootstrap";

function StaticHeroArea2(props) {
  return (
    <section
      className="static-landing-page-section static-landing-page-hero"
      style={{
        backgroundImage: `url(${props.heroGraphic})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh", // Adjust as per your design needs
        position: "relative", // Set position relative for absolute positioning inside
      }}
    >
      <div className="static-hero-area-text-container-alt">
        <h1>Didn't Find the Service You Were Looking For?</h1>
        <h3>
          At Acctech, we offer a diverse range of services that cater to your
          every need. Explore our comprehensive offerings and discover the right
          solution for you.
        </h3>
        <Button as="a" href="#services" variant="primary">
          Discover Now
        </Button>{" "}
      </div>
    </section>
  );
}

export default StaticHeroArea2;
