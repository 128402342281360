import React from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import styled, { keyframes, css } from "styled-components";
import { slideInLeft } from "react-animations";
import { useInView } from "react-intersection-observer";

// Define our slideInLeft animation using keyframes
const slideInAnimation = keyframes`${slideInLeft}`;

// Create a styled div that will get the animation
const AnimatedDiv = styled.div`
  text-align: left;
  position: absolute;
  top: 25%;
  left: 0%;
  transform: translateY(-20%);
  background-color: #05142df6;
  padding: 20px;
  width: 40%;
  height: auto;
  padding: 50px;
  padding-right: 40px;

  // Apply the animation when the prop animate is true
  animation: ${(props) =>
    props.animate
      ? css`
          ${slideInAnimation} 1s forwards
        `
      : "none"};
  @media (max-width: 900px) {
    top: 20%;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    padding: 20px;
    border-radius: 0;
    padding-bottom: 30px;
  }
`;

function StaticHeroArea(props) {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: false, // Animation will trigger each time it comes into view
  });

  return (
    <section
      ref={ref}
      className="static-landing-page-section static-landing-page-hero"
      style={{
        backgroundImage: `url(${props.heroGraphic})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        position: "relative",
      }}
    >
      <AnimatedDiv animate={inView}>
        <div className="static-hero-area-text-container">
          <h1>Digitally Transform Your Business</h1>
          <h3>
            Streamline and optimize your business operations using our
            cutting-edge web app, digitally transforming complex tasks into
            efficient workflows.
            {/* Streamline and optimize your business operations with our
            cutting-edge web app. */}
            {/* Experience the power of digitally transforming
            intricate processes into simpler, efficient workflows */}
            {/* Using our state of the art digital solution we can transform your
            business by simplifying complicated tasks. */}
          </h3>
          <Button as="a" href="#feature" variant="primary">
            Explore Now
          </Button>
        </div>
      </AnimatedDiv>
    </section>
  );
}

export default StaticHeroArea;
