// src/services/getPayrollReportService.js
export const getPayrollReport = async (
  idToken,
  companyId,
  startDate,
  endDate
) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/timecard/getPayrollReport?companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      mode: "cors",
    }
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};
