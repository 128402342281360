// TopNavbar.js
import React, { useState } from "react";
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import { Link as ScrollLink } from "react-scroll";

export default function TopNavbar({
  logo,
  children,
  onNavClick,
  expanded,
  setExpanded,
}) {
  // Create a new function that collapses the navbar and invokes the onNavClick
  const handleNavClick = () => {
    setExpanded(false);
    if (onNavClick) onNavClick();
  };

  return (
    <Navbar
      className={`static-landing-page-navbar ${expanded ? "expanded" : ""}`}
      expand="lg"
      fixed="top"
      expanded={expanded}
    >
      <Container>
        <Navbar.Brand href="/" className="static-landing-page-logo">
          <img
            src={logo}
            alt="ACCTech Logo"
            height="50"
            width="auto"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle onClick={() => setExpanded((prev) => !prev)} />
        <Navbar.Collapse>
          {/* Here, we're injecting the handleNavClick function into the children */}
          {React.Children.map(children, (child) =>
            React.cloneElement(child, { onClick: handleNavClick })
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
