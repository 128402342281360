import React, { useState } from "react";
import {
  Container,
  Navbar,
  Nav,
  Button,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import { Link as ScrollLink } from "react-scroll";
import logo from "../images/logos/LogoDarkLongAlt.png";
import heroImage from "../images/slider/hero-area2.jpg";
import heroGraphic from "../images/graphics/landing1.png";
import heroGraphic2 from "../images/graphics/landing2.png";

import TopNavbar from "../navigation/TopNavbar";
import { FaPalette, FaBriefcase, FaFlask } from "react-icons/fa";
import StaticAboutUsPage from "./staticPages/StaticAboutUsPage";
import StaticServicesPage from "./staticPages/StaticServicesPage";
import StaticPlansPage from "./staticPages/StaticPlansPage";
import StaticHeroArea from "./staticPages/StaticHeroArea";
import StaticHeroArea2 from "./staticPages/StaticHeroArea2";
import StaticFeaturesSection from "./staticPages/StaticFeaturesSection";
import StaticContact from "./staticPages/StaticContact";
import StaticFooter from "./staticPages/StaticFooter";

export default function StaticLandingPage() {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="static-landing-page-root">
      {/* Navbar */}
      <TopNavbar expanded={expanded} setExpanded={setExpanded} logo={logo}>
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <Nav.Item>
              <ScrollLink
                onClick={() => setExpanded(false)}
                to="feature"
                smooth={true}
                duration={150}
                activeClass="active-nav-link"
                spy={true}
                hashSpy={true}
              >
                Key Features
              </ScrollLink>
            </Nav.Item>
            <Nav.Item>
              <ScrollLink
                onClick={() => setExpanded(false)}
                to="about"
                smooth={true}
                duration={150}
                activeClass="active-nav-link"
                spy={true}
                hashSpy={true}
              >
                About
              </ScrollLink>
            </Nav.Item>
            <Nav.Item>
              <ScrollLink
                onClick={() => setExpanded(false)}
                to="services"
                smooth={true}
                duration={150}
                activeClass="active-nav-link"
                spy={true}
                hashSpy={true}
              >
                Services
              </ScrollLink>
            </Nav.Item>
            <Nav.Item>
              <ScrollLink
                onClick={() => setExpanded(false)}
                to="contact"
                smooth={true}
                duration={150}
                activeClass="active-nav-link"
                spy={true}
                hashSpy={true}
              >
                Contact
              </ScrollLink>
            </Nav.Item>
          </Nav>
          <Button className="static-landing-page-portal-button" href="/portal">
            Portal
          </Button>
        </Navbar.Collapse>
      </TopNavbar>
      {/* Hero Section */}
      <StaticHeroArea heroImage={heroImage} heroGraphic={heroGraphic} />

      {/* Features Section */}
      <StaticFeaturesSection />

      {/* Hero Section 2 */}
      <StaticHeroArea2 heroGraphic={heroGraphic2} />

      {/* About Section */}
      <StaticAboutUsPage />

      {/* Services Section */}
      <StaticServicesPage />

      {/* Commented Plans Section, uncomment if needed */}
      {/* <StaticPlansPage /> */}

      {/* Contact Section */}
      <StaticContact />

      {/* Footer */}
      <StaticFooter />
    </div>
  );
}
