import React, { useState, useEffect } from "react";
import Screen from "../components/Screen";
import { Card, Container, Row, Col, Spinner, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { FaFileInvoiceDollar } from "react-icons/fa";
import DetailsCard from "../components/DetailsCard";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getEmployeesByCompany } from "../services/getEmployeesService";
import { useSelector, useDispatch } from "react-redux";
import { selectTokens, logout } from "../store/userSlice";
import ErrorModal from "../components/ErrorModal";
import EmployeeList from "../components/EmployeeList";
import EmployeeForm from "../components/EmployeeForm";
import { createEmployee } from "../services/employeeServices";
import { Link } from "react-router-dom";

export default function CompanyScreen() {
  const location = useLocation();
  const companyData = location.state?.companyData;
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);
  const [showEmployeeModal, setShowEmployeeModal] = useState(false);
  const { idToken } = useSelector(selectTokens);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const navigate = useNavigate(); // <-- useNavigate hook

  // Check if company_id exists in companyData. If not, redirect to /portal
  useEffect(() => {
    // console.log(companyData);
    if (companyData === undefined) {
      navigate("/portal");
    }
  }, [companyData, navigate]);

  const handleLogout = () => {
    dispatch(logout());
  };

  const {
    data: employees,
    isLoading,
    refetch,
  } = useQuery(
    ["employees", companyData?.company_id],
    () => getEmployeesByCompany(idToken, companyData?.company_id),
    {
      onError: (error) => {
        setError(error);
        setShow(true);
      },
    }
  );

  // console.log(employees);

  const mutation = useMutation(
    (newEmployee) => createEmployee(idToken, newEmployee),
    {
      onSuccess: () => {
        // Invalidate and refetch the employee list after a successful mutation
        queryClient.invalidateQueries(["employees", companyData?.company_id]);
      },
    }
  );

  return companyData ? (
    <Screen backButton title={`Company: ${companyData?.name}`}>
      <ErrorModal
        continueButton={() => {
          handleLogout();
          setError(false);
          setShow(false);
        }}
        show={show}
        message={{
          title: `${
            error?.message === "HTTP error! status: 401"
              ? "Session expired!"
              : "error"
          }`,
          body: `Please try logging in again. ${
            error?.message === "HTTP error! status: 401"
              ? ""
              : `Error message: ${error}`
          }`,
          buttonText: "Continue",
        }}
      />
      <Container style={{ paddingBottom: 100 }}>
        <Row>
          <Col md={10}>
            <DetailsCard data={companyData} dataType="company" />

            <EmployeeForm
              company_id={companyData.company_id}
              showModal={showEmployeeModal}
              setShowModal={setShowEmployeeModal}
            />
          </Col>
        </Row>

        <Link
          to="/portal/payrollReport"
          state={{ companyData: companyData }}
          className="button-link-wrapper"
        >
          <Button className="button-secondary payroll-report-button">
            <FaFileInvoiceDollar />
            Generate Payroll Report
          </Button>
        </Link>
        <div className="secondaryHeader">Employees List:</div>
        <Button
          variant="primary"
          className="button-primary create-company-button"
          style={{ marginTop: "20px", marginBottom: "20px" }}
          onClick={() => setShowEmployeeModal(true)}
        >
          Create Employee
        </Button>
        <Col md={10}>
          {isLoading ? (
            <div className="text-center">
              <Spinner animation="border" role="status"></Spinner>
            </div>
          ) : (
            <EmployeeList employees={employees} isLoading={isLoading} />
          )}
        </Col>
      </Container>
    </Screen>
  ) : (
    <ErrorModal
      continueButton={() => {
        handleLogout();
        setError(false);
        setShow(false);
      }}
      show={show}
      message={{
        title: `${
          error?.message === "HTTP error! status: 401"
            ? "Session expired!"
            : "error"
        }`,
        body: `Please try logging in again. ${
          error?.message === "HTTP error! status: 401"
            ? ""
            : `Error message: ${error}`
        }`,
        buttonText: "Continue",
      }}
    />
  );
}
