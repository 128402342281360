import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import {
  Spinner,
  Card,
  Container,
  Row,
  Col,
  Button,
  Alert,
} from "react-bootstrap";
import { FaBuildingUser } from "react-icons/fa6";
import { getCompaniesByUser } from "../services/getCompaniesService";
import { selectTokens } from "../store/userSlice";
import { setCompanies } from "../store/companiesSlice";
import { createCompany } from "../services/companiesServices";
import CompanyForm from "../components/CompanyForm";
import CompaniesList from "../components/CompaniesList";

export default function Companies({ setShow, setError, screen }) {
  const dispatch = useDispatch();
  const { idToken } = useSelector(selectTokens);
  const queryClient = useQueryClient();
  const [alertMessage, setAlertMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [companyForm, setCompanyForm] = useState({
    name: "",
    address: "",
  });

  const {
    isLoading,
    error,
    data: companies,
  } = useQuery("companies", () => getCompaniesByUser(idToken), {
    onSuccess: (data) => {
      dispatch(setCompanies(data));
    },
  });

  const createNewCompany = async () => {
    try {
      const response = await createCompany(idToken, companyForm);
      setAlertMessage({
        name: "Successfully created the following company: " + response.name,
        type: "success",
      });
      queryClient.invalidateQueries("companies");
      setShowModal(false); // Close the modal
    } catch (error) {
      setAlertMessage({
        name: "There was an error while creating your company. Please try again.",
        type: "error",
      });
    }
  };

  // console.log(companies);

  const handleClick = (companyId) => {
    // console.log(`Company with ID ${companyId} clicked.`);
  };

  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status"></Spinner>
      </div>
    );
  }

  if (error && !isLoading) {
    setShow(true);
    setError(error.message);
  }

  return (
    <div>
      {alertMessage && (
        <Alert
          variant={alertMessage.type === "success" ? "success" : "danger"}
          onClose={() => setAlertMessage(null)}
          dismissible
          className="alert-style"
          style={{ position: "fixed" }}
        >
          {alertMessage.name}
        </Alert>
      )}

      {screen === "payroll" && (
        <>
          <Button
            className="button-primary create-company-button"
            onClick={() => setShowModal(true)} // Open the modal
          >
            Create Company
          </Button>
          <CompanyForm
            showModal={showModal}
            setShowModal={setShowModal}
            companyForm={companyForm}
            setCompanyForm={setCompanyForm}
            createNewCompany={createNewCompany}
          />
        </>
      )}
      {companies ? (
        <CompaniesList
          companies={companies}
          handleClick={handleClick}
          screen={screen}
        />
      ) : (
        <p>There was an error loading your companies please try again</p>
      )}
    </div>
  );
}
