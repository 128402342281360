// export const getAllDocuments = async (idToken) => {
//   try {
//     const response = await fetch(
//       `${process.env.REACT_APP_BASE_URL}/documents/all`,
//       {
//         method: "GET",
//         headers: {
//           Authorization: `Bearer ${idToken}`,
//         },
//         mode: "cors",
//       }
//     );

//     if (!response.ok) {
//       const errorBody = await response.text();
//       console.error("Error response body:", errorBody);
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }

//     const data = await response.json();
//     return data;
//   } catch (error) {
//     // Log the error for debugging purposes
//     console.error("Error fetching all documents:", error);
//     throw error;
//   }
// };

// Fetches all top-level folders (companies)
export const getAllDocuments = async (idToken, folderPath = "") => {
  // Construct the URL based on whether a folderPath is provided
  let url = `${process.env.REACT_APP_BASE_URL}/documents/all`;
  if (folderPath) {
    // If a specific folderPath is provided, include it as a query parameter
    url += `?folderPath=${encodeURIComponent(folderPath)}`;
  }

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      mode: "cors",
    });

    if (!response.ok) {
      const errorBody = await response.text();
      console.error("Error response body:", errorBody);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching documents:", error);
    throw error;
  }
};
