import React, { useState } from "react";
import Navbar from "../navigation/Navbar";
import { logout } from "../store/userSlice";
import { useDispatch } from "react-redux";
import { FaArrowLeft, FaBars } from "react-icons/fa6"; // Importing the hamburger icon
import { useNavigate } from "react-router-dom";
import useScreenWidth from "../hooks/useScreenWidth";
import { FaRightFromBracket, FaGear } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import logo from "../images/logos/LogoDarkLongAlt.png";

export default function Screen({ children, title, backButton }) {
  const screenWidth = useScreenWidth();

  const isMobile = screenWidth > 1300 ? false : true;

  const [isNavVisible, setIsNavVisible] = useState(!isMobile); // State to toggle Navbar visibility
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const titleText = title.split(" ");

  // console.log(screenWidth);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="screen-container">
      <div className="top-menu">
        <img
          src={logo}
          alt="ACCTech Logo"
          height="40"
          width="auto"
          className="d-inline-block align-top"
        />
        <FaBars size={30} onClick={() => setIsNavVisible(!isNavVisible)} />{" "}
        {/* Hamburger icon */}
      </div>
      {isNavVisible && (
        <div
          className={`nav-container ${
            isNavVisible ? "nav-container-show" : null
          }`}
        >
          {isMobile && (
            <a
              onClick={() => setIsNavVisible(!isNavVisible)}
              className="mobile-nav-close"
            >
              <AiOutlineClose size={25} />
            </a>
          )}

          <Navbar handleLogout={handleLogout} />
        </div>
      )}
      <div className="content-container">
        <div className="screen-title-container">
          {backButton ? (
            <h1 onClick={() => navigation(-1)} className="screen-back">
              <FaArrowLeft size={16} className="navIcon" />
              <span style={{ color: "#213555", fontSize: 16 }}>Go back</span>
            </h1>
          ) : null}

          <h1 className="screen-title">
            <span style={{ color: "#213555" }}>{titleText[0]}</span>{" "}
            <span style={{ color: "#326697" }}>{titleText[1]}</span>
          </h1>
        </div>
        <div className="screen-content-container">{children}</div>
      </div>
    </div>
  );
}
