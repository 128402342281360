import React from "react";
import ServicesPlanCard from "../components/ServicesPlanCard";

export default function OwnerLandingScreen({ setError, setShow }) {
  const ServicesPlanCardData = [
    {
      title: "Payroll",
      data: [
        "Add or remove employees",
        "Fill in employee hours",
        "Adjust pay rates",
      ],
      price: "55/M",
      hasPlan: true,
      link: "/payroll",
    },
    {
      title: "Tax",
      data: [
        "Easily upload tax documents",
        "Chat with experienced tax experts",
        "File tax reports directly in-app",
      ],
      price: "55/M",
      hasPlan: false,
    },

    {
      title: "POS System",
      data: [
        "Real-time sales & inventory",
        "Secure encryption & backups",
        "Scalable for business growth",
      ],
      price: "55/M",
      hasPlan: false,
    },
    // {
    //   title: "Bookkeeping",
    //   data: [
    //     "Upload Tax Documents",
    //     "Chat with Tax experts",
    //     "File Tax report directly on the app",
    //   ],
    //   price: "55/M",
    //   hasPlan: false,
    // },
  ];

  return (
    <>
      <div className="landing-text-container">Our Plans:</div>

      <div className="service-plan-cards-container">
        {ServicesPlanCardData.map((plan) => (
          <ServicesPlanCard
            key={plan.title}
            plan={plan}
            setError={setError}
            setShow={setShow}
          />
        ))}
      </div>
    </>
  );
}
