// src/services/getEmployeesService.js
export const getEmployeesByCompany = async (idToken, companyId) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/employee/getEmployeesByCompany/${companyId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      mode: "cors",
    }
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};
