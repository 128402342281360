import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import userReducer from "./userSlice";
import companiesReducer from "./companiesSlice";
import timeCardSlice from "./timeCardSlice";

const reducers = {
  user: userReducer,
  companies: companiesReducer,
  timeCardData: timeCardSlice,
};

const persistConfig = {
  key: "root", // You can set a root key to persist the entire state tree
  storage,
  whitelist: ["user", "timeCardData"], // List the reducers you want to persist
};

const rootReducer = combineReducers(reducers);

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export const persistor = persistStore(store);
