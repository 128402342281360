import React from "react";
import { Link, useLocation } from "react-router-dom";
import Divider from "../components/Divider";
import { FaHome } from "react-icons/fa";
import {
  FaRightFromBracket,
  FaGear,
  FaFileInvoiceDollar,
  FaFileLines,
} from "react-icons/fa6";
import logo from "../images/logos/LogoDarkLongAlt.png";
import { selectUserDetails } from "../store/userSlice";
import { useSelector } from "react-redux";

function Navbar({ handleLogout }) {
  const location = useLocation();
  const userDetails = useSelector(selectUserDetails);
  const isAdmin = true;

  return (
    <nav className="navbar full-height">
      <div className="logo-container">
        <img
          src={logo}
          alt="ACCTech Logo"
          height="55"
          width="auto"
          className="d-inline-block align-top"
        />
      </div>

      <ul className="nav-links-container">
        <Divider />

        <li>
          <Link
            className={`nav-item ${
              location.pathname === "/portal" ? "active-nav-item" : ""
            }`}
            to="/portal"
          >
            <FaHome size={25} className="navIcon" />
            <span className="navText">Home</span>
          </Link>
        </li>
        <Divider />

        {isAdmin && (
          <li>
            <Link
              className={`nav-item ${
                location.pathname === "/portal/admin" ? "active-nav-item" : ""
              }`}
              to="/portal/admin"
            >
              <FaFileInvoiceDollar size={25} className="navIcon" />
              <span className="navText">Admin</span>
            </Link>
          </li>
        )}
        <Divider />

        {userDetails.isOwner === true && (
          <li>
            <Link
              className={`nav-item ${
                location.pathname === "/portal/payroll" ? "active-nav-item" : ""
              }`}
              to="/portal/payroll"
            >
              <FaFileInvoiceDollar size={25} className="navIcon" />
              <span className="navText">Payroll</span>
            </Link>
          </li>
        )}
        <Divider />

        {userDetails.isOwner === true && (
          <li>
            <Link
              className={`nav-item ${
                location.pathname === "/portal/documents"
                  ? "active-nav-item"
                  : ""
              }`}
              to="/portal/documents"
            >
              <FaFileLines size={25} className="navIcon" />
              <span className="navText">Docs</span>
            </Link>
          </li>
        )}

        {userDetails.accountType === "personal" && (
          <li>
            <Link
              state={{ companyData: { name: "personal" } }}
              className={`nav-item ${
                location.pathname === "/portal/documents"
                  ? "active-nav-item"
                  : ""
              }`}
              to="/portal/documents/viewDocs"
            >
              <FaFileLines size={25} className="navIcon" />
              <span className="navText">Docs</span>
            </Link>
          </li>
        )}
        <Divider />

        <li>
          <Link
            className={`nav-item ${
              location.pathname === "/portal/setting" ? "active-nav-item" : ""
            }`}
            to="/portal/setting"
          >
            <FaGear size={25} className="navIcon" />
            <span className="navText">Setting</span>
          </Link>
        </li>
        <Divider />

        <li>
          <Link className="nav-item" onClick={handleLogout}>
            <FaRightFromBracket size={25} className="navIcon" />
            <span className="navText">Logout</span>
          </Link>
        </li>
        <Divider />
      </ul>

      <div className="nav-bottom-container">
        <Link className="nav-link nav-link-bottom" to="/">
          <span className="navText">Back to Landing Screen</span>
        </Link>
      </div>
    </nav>
  );
}

export default Navbar;
